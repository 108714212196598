import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';
import ComponentList from 'components/ComponentList';
import { useEffect, useState } from 'react';
import {
	askDeleteComponent,
	ComponentsSearchParams,
	createComponent,
	deleteComponent,
	updateComponent,
} from 'lib/models/components';
import Alert, { AlertsTypes } from 'components/Alert';
import ConfirmationDialog from 'components/ConfirmationDialog';
import ComponentForm from 'components/ComponentList/ComponentForm';
import { Box, DialogContentText, LinearProgress } from '@material-ui/core';

import useComponents from 'hooks/useComponents';

function Components() {
	const { t } = useTranslation();
	const history = useHistory();

	const { components, refetch, loading, setLoading } = useComponents();

	const [component, setComponent] = useState<ComponentType>({ name: '', id: '', types: [] });
	const [open, setOpen] = useState(false);
	const [success, setSuccess] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [modifyQuantity, setModifyQuantity] = useState(0);
	const [deleted, setDeleted] = useState(false);

	const [filters, setFilters] = useState<ComponentsSearchParams>({ types: [], text: '' });

	const handleFilters = <K extends keyof ComponentsSearchParams>(key: K, value: ComponentsSearchParams[K]) => {
		setFilters((prevFilters) => {
			return { ...prevFilters, [key]: value };
		});
	};
	useEffect(() => {
		refetch(filters);
	}, [refetch, filters]);

	const [error, setError] = useState('');

	const edit = async (component: ComponentType) => {
		setLoading(true);
		const { success, items } = await askDeleteComponent(component);
		if (success) {
			setModifyQuantity(items);
			setComponent(component);
			setOpen(true);
		}
		setLoading(false);
	};

	const onDelete = async (component: ComponentType) => {
		const { success, items } = await askDeleteComponent(component);
		if (success) {
			setModifyQuantity(items);
			setConfirmDeleteModal(true);
			setComponent(component);
		}
	};
	const save = async () => {
		setLoading(true);
		try {
			component._id ? await updateComponent(component) : await createComponent(component);
			setSuccess(true);
			refetch(filters);
			closeModal();
		} catch (error) {
			console.error(error);
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
		resetAlert();
	};

	const onUpdate = async (value: string, key: keyof ComponentType) => {
		if (key === 'name' && typeof value === 'string') {
			setComponent((prevItem) => ({ ...prevItem, name: value }));
		} else if (key === 'types' && typeof value === 'string') {
			setComponent((prevItem) => {
				if (prevItem[key] !== undefined) {
					const values = prevItem[key].includes(value)
						? prevItem[key].filter((i) => i !== value)
						: [...prevItem[key], value];
					return { ...prevItem, [key]: values };
				} else return prevItem;
			});
		}
	};

	const confirmDelete = async (confirm: boolean) => {
		if (confirm) {
			try {
				await deleteComponent(component);
				setDeleted(true);
				refetch(filters);
			} catch (error: any) {
				console.error(error);
				setError(error.message || error);
			}
		}
		setComponent({ name: '', id: '', types: [] });
		setConfirmDeleteModal(false);
		resetAlert();
	};

	const closeModal = () => {
		setOpen(false);
		setComponent({ name: '', id: '', types: [] });
		setModifyQuantity(0);
	};

	const resetAlert = () => {
		setTimeout(() => {
			setSuccess(false);
			setError('');
			setDeleted(false);
		}, 3000);
	};

	const dialogDescription =
		modifyQuantity > 0
			? t('components:existent_items_on_delete_description')
			: t('components:delete_component_description', { quantity: modifyQuantity });

	return (
		<ScreenContainer title={t('components:component_plural')}>
			{!!loading && <LinearProgress />}

			<CreateIcon
				onCreate={() => {
					setOpen(true);
				}}
			/>

			<ComponentList
				components={components}
				edit={edit}
				onDelete={onDelete}
				refetch={refetch}
				filters={filters}
				handleFilters={handleFilters}
			/>

			{confirmDeleteModal && modifyQuantity === 0 && (
				<ConfirmationDialog
					onClose={confirmDelete}
					title={t('components:delete_component')}
					description={dialogDescription}
				/>
			)}

			{confirmDeleteModal && modifyQuantity > 0 && (
				<ConfirmationDialog
					confirmButton={t('common:cancel')}
					onClose={() => {
						history.push(`/items?component=${component.name}`);
					}}
					hideCancel={true}
					title={t('system:errorTryingDelete')}
				>
					<Box>
						<DialogContentText>{t('components:existent_items_on_delete_title')}.</DialogContentText>
						<DialogContentText>{dialogDescription}</DialogContentText>
					</Box>
				</ConfirmationDialog>
			)}
			{open && (
				<ComponentForm
					title={component.id ? t('components:edit_component') : t('components:add_component')}
					item={component}
					onUpdate={onUpdate}
					onSubmit={save}
					onCancel={closeModal}
					description={
						modifyQuantity > 0 && component._id
							? t('components:edit_component_description', { quantity: modifyQuantity })
							: undefined
					}
				/>
			)}
			{!loading && success && (
				<Alert
					message={t('common:success')}
					show={success}
					type={AlertsTypes.success}
					onClose={() => setSuccess(false)}
				/>
			)}

			{!loading && deleted && (
				<Alert
					message={t('common:deleted')}
					show={deleted}
					type={AlertsTypes.success}
					onClose={() => setDeleted(false)}
				/>
			)}

			{!loading && error && (
				<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />
			)}
		</ScreenContainer>
	);
}

export default Components;
