const schools = {
	notFound: 'Escuela {{name}} no encontrada',
	deleteTitle: 'Eliminación de escuela',
	deleteText: '¿Desea eliminar la escuela seleccionada?',
	deleteText_plural: '¿Desea eliminar las escuelas seleccionadas?',
	confirmImportTitle: 'Confirmación de importación',
	confirmImportDescription: 'Se importarán {{totalToImport}} de {{amount}} escuelas',
	importedDescription: '{{importedAmount}} escuelas importadas',
	invalidSchool: 'Escuela en línea ({{index}})',
	invalidSchools: 'Escuelas inválidas ({{amount}})',
	importDescriptionWarning: 'Todas las escuelas con el mismo nombre serán reemplazadas.',
	invalidFile: 'Archivo inválido, las columnas con nombre "name" y "levels" deben existir',
	uploadUsersError: 'Las siguientes escuelas no fueron importadas ({{schools}})',
	createSchool: 'Crear escuela',
	editSchool: 'Editar escuela',
	creationSuccessTitle: 'Escuela creada',
	creationSuccessDescription: 'La escuela "{{name}}" fué creada correctamente.',
	level: 'Nivel',
	teachers: 'Docentes',
	firstCreateSchoolMessage: 'Para agregar docentes es necesario primero guardar la escuela.',
	search: 'Buscar escuela',
	syncTitle: 'Sincronizar usuarios',
	syncText:
		'Advertencia, al sincronizar los usuarios con los datos de "Hubspot", se consumirán parte de su cuota de acceso al sistema, ¿desea continuar con la actualización?',
	syncSchoolsTitle: 'Sincronizar escuelas',
	syncSchoolsText:
		'Advertencia, al sincronizar las escuelas con los datos de "Hubspot", se consumirán parte de su cuota de acceso al sistema, ¿desea continuar con la actualización?',
	syncButton: 'Si',
	warningUploader:
		'ADVERTENCIA: si el archivo que estás subiendo contiene escuelas que ya están presentes en la base de datos, los sobrescribirá con los nuevos valores y se perderán todos los cambios que hayas realizado en los mismos.',
	syncNewSchools: 'Buscar nuevas escuelas en hubspot',
	syncSchoolsCurrentPage: 'Actualizar la página actual con datos de hubspot',
	syncAllWarningText:
		'ADVERTENCIA: Esta actualización realizará múltiples pedidos a "hubspot" y puede demorar varios minutos',
	syncSchoolsErrorsTitle: 'Errores de sincronización de escuelas',
	syncAllErrorWarningText:
		'Las siguientes escuelas no pudieron ser importadas/actualizadas debido a los errores listados más abajo:',
	syncTeachersErrorsTitle: 'Errores de sincronización de docentes',
	syncAllTeachersErrorWarningText:
		'Los siguientes docentes no pudieron ser importados/actualizados debido a los errores listados más abajo:',
};

export default schools;
