import { Box, Button, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import PaperContainer from 'components/containers/PaperContainer';
import { useTranslation } from 'react-i18next';

type Props = {
	loading?: boolean;
	onSelect?(list: List): any;
	setOpenModal?(): void;
	subjects: SubjectType[];
	edit: (subjects: SubjectType) => void;
	onDelete: (subjects: SubjectType) => void;
};

function SubjectsTable({ subjects, onDelete, edit }: Props) {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Box>
			{subjects.map((c) => {
				return (
					<PaperContainer key={c.id} className={classes.card}>
						<Box>
							<Typography>
								<strong>{c.name}</strong>
							</Typography>
							{/* <Typography sx={{ mb: 2 }}>{`${el.items.length} ${t('common:products')}`}</Typography> */}
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'flex-end',
								paddingLeft: 2,
							}}
							className={classes.actions}
						>
							<Button
								variant="contained"
								fullWidth={true}
								color="primary"
								size="medium"
								startIcon={<Edit />}
								onClick={() => edit(c)}
							>
								{t('common:edit')}
							</Button>
							<Button
								variant="contained"
								fullWidth={true}
								color="secondary"
								size="medium"
								startIcon={<Delete />}
								onClick={() => onDelete(c)}
							>
								{t('common:delete')}
							</Button>
						</Box>
					</PaperContainer>
				);
			})}
		</Box>
	);
	// return <ComponentsTable {...props} />;
}

export default SubjectsTable;

const useStyles = makeStyles((theme) => {
	return createStyles({
		card: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: theme.spacing(2),
			padding: theme.spacing(1),
		},
		edit: {},
		delete: {},
		actions: {
			gap: theme.spacing(1),
		},
	});
});
