// import ComponentsTable from './ComponentTable';

import FormatsTable from './FormatsTable';

type Props = {
	formats: FormatType[];
	edit: (component: FormatType) => void;
	onDelete: (component: FormatType) => void;
};
function FormatsList(props: Props) {
	return <FormatsTable {...props} />;
}

export default FormatsList;
