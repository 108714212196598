const subjects = {
	subject_plural: 'Subjects',
	add_subject: 'Add subject',
	edit_subject: 'Edit subject',
	edit_subject_description: 'The subject of {{quantity}} books will be updated',
	delete_subject: 'Delete selected subject?',
	delete_subject_description: 'The subject will be removed from {{quantity}} items',
	existent_items_on_delete_title: 'There are items assigned with this subject',
	existent_items_on_delete_description: 'Modify the items with this subject before deleting the subject.',
} as const;

export default subjects;
