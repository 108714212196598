const certificates = {
	certificate_plural: 'Certificados',
	add_certificate: 'Agregar certificado',
	edit_certificate: 'Editar certificado',
	edit_certificate_description: 'Se actualizará el certificado asignado de {{quantity}} libros.',
	delete_certificate: 'Borrar el certificado seleccionado?',
	delete_certificate_description: 'El certificado sera eliminado de {{quantity}} items',
	existent_items_on_delete_title: 'Existen elementos asignados con este certificado',
	existent_items_on_delete_description: 'Modifica el certificado de los items antes de eliminar el certificado.',
} as const;

export default certificates;
