import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Alert, { AlertsTypes } from 'components/Alert';
import {
	Button,
	createStyles,
	FormGroup,
	LinearProgress,
	makeStyles,
	TextField,
	Theme,
	Box,
	Typography,
	FormControlLabel,
	Checkbox,
	Modal,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import AddIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';

import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';
import ConfirmationDialog from 'components/ConfirmationDialog';
import InputContainer from 'components/containers/InputContainer';
import UserEditionForm from 'components/UserEditionForm';
import UserTable from 'components/UserTable';

import RefreshIcon from '@material-ui/icons/Refresh';

import { fetchSchool, updateSchool, refreshTeachers, TeacherError } from 'lib/models/schools';

import { isUser } from 'lib/models/users';
import { sort } from 'lib/helpers';
import { AcademicLevel, Grade, levels } from 'teachers-types';
import { provinces } from 'types/constants';
import useCurriculums from 'hooks/useCurriculums';

// import { updateUserSchool } from 'lib/models/users';

function SchoolEdition() {
	const { t } = useTranslation();

	const history = useHistory();
	const classes = useStyles();
	const { id } = useParams<{ id: string }>();

	const newSchool = !id || id === 'new';

	const title = newSchool ? t('schools:createSchool') : t('schools:editSchool');

	const [loading, setLoading] = useState<boolean>(true);
	const [school, setSchool] = useState<EditSchool>({
		_id: '',
		levels: [],
		curriculum: [],
		name: '',
		province: 'Buenos Aires',
	});
	const [originalSchool, setOriginalSchool] = useState<EditSchool>({
		_id: '',
		levels: [],
		curriculum: [],
		name: '',
		province: 'Buenos Aires',
	});
	const [error, setError] = useState<string>('');
	const [errors, setErrors] = useState<TeacherError[]>([]);
	const [success, setSuccess] = useState<boolean>(false);
	const [showTeacherModal, setShowTeacherModal] = useState<true | User | null>(null);
	const [users, setUsers] = useState<User[]>([]);
	const [showRefreshModal, setShowRefreshModal] = useState(false);
	const [showSavingConfirmation, setShowSavingConfirmation] = useState(false);
	const { curriculums } = useCurriculums();

	const edited =
		school.name !== originalSchool.name ||
		school.province !== originalSchool.province ||
		school.levels.join() !== originalSchool.levels.join() ||
		school.curriculum.join() !== originalSchool.curriculum.join();

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchSchool(id);
		if (response) {
			setSchool(response);
			setOriginalSchool(response);
			setUsers(response.users);
		}
		setLoading(false);
	}, [id]);

	useEffect(() => {
		const init = async () => {
			if (!id || id === 'new') {
				setLoading(false);
				return;
			}
			refetch();
		};
		init();
	}, [id, refetch]);

	const onUpdate = (key: keyof School) => {
		return (event: any) => {
			const value = event.target.value;

			if (school) {
				setSchool({ ...school, [key]: value });
			}
		};
	};

	const save = async () => {
		try {
			setLoading(true);
			const updatedSchool = await updateSchool(school);
			setSchool(updatedSchool);
			setOriginalSchool(updatedSchool);
			setSuccess(true);
			history.push(`/schools/${updatedSchool._id!}`);
		} catch (error) {
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
		setShowSavingConfirmation(false);
	};

	function toggleCurriculum(curriculum: string) {
		setSchool((current) => {
			const found = current.curriculum.includes(curriculum);
			return {
				...current,
				curriculum: found
					? current.curriculum.filter((currentCurriculum) => currentCurriculum !== curriculum)
					: [...current.curriculum, curriculum],
			};
		});
	}

	function toggleLevel(level: AcademicLevel, grade?: Grade) {
		const levelFilter: LevelFilter = grade ? `${level}|${grade}` : level;
		const schoolLevelFound = school.levels.find((schoolLevel) => schoolLevel.includes(levelFilter));
		// remove level
		if (schoolLevelFound) {
			// We have to remove also all the grades filters for this AcademicLevel
			if (!grade) {
				setSchool((school) => {
					return { ...school, levels: school.levels.filter((schoolLevel) => !schoolLevel.includes(level)) };
				});
				return;
			}
			// Remove only the grade
			setSchool((school) => {
				return { ...school, levels: school.levels.filter((schoolLevel) => schoolLevel !== levelFilter) };
			});
			return;
		}
		// add level
		setSchool((school) => {
			// If academic level checkbox was touched, add all the grades
			if (!grade) {
				const levelFilters: LevelFilter[] =
					levels[level].length > 0
						? levels[level].map((grade) => {
								const levelFilter: LevelFilter = `${level}|${grade}`;
								return levelFilter;
						  })
						: [level];
				return { ...school, levels: [...school.levels, ...levelFilters] };
			}
			return { ...school, levels: [...school.levels, levelFilter] };
		});
		return;
	}

	async function onTeacherEdition(teacher?: User | null, deleted?: boolean) {
		setShowTeacherModal(null);
		if (teacher) {
			setUsers((current) => {
				if (!deleted) {
					return !current.find((user) => user.username === teacher.username)
						? [...current, teacher]
						: current.map((user) => {
								return user.username === teacher.username ? teacher : user;
						  });
				}
				return current.filter((user) => user.username !== teacher.username);
			});
		}
	}

	async function refresh() {
		setErrors([]);
		if (!school) {
			setShowRefreshModal(false);
			return;
		}
		try {
			setLoading(true);
			const { users, errors: teacherErrors } = await refreshTeachers(school.hubspotId!);
			setUsers(users);
			if (teacherErrors.length) {
				setErrors(teacherErrors);
			}
		} catch (error) {
			console.warn(error);
		}
		setLoading(false);
		setShowRefreshModal(false);
	}

	const schoolCreated = !loading && success && newSchool;

	return (
		<ScreenContainer title={title}>
			<PaperContainer>
				{loading && <LinearProgress className={classes.input} />}

				{!!errors.length && (
					<ConfirmationDialog
						title={t('schools:syncTeachersErrorsTitle')}
						onClose={() => setErrors([])}
						loading={false}
						warningMessage={t('schools:syncAllTeachersErrorWarningText')}
					>
						<div className={classes.errorsContainer}>
							{errors.map((error) => {
								return (
									<div className={classes.errorContainer}>
										<Typography key={error.id}>
											{error.email} ({error.id}): {error.error}
										</Typography>
									</div>
								);
							})}
						</div>
					</ConfirmationDialog>
				)}

				<FormGroup>
					<TextField
						id="name"
						label={t('common:name')}
						fullWidth={true}
						value={school.name}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('name')}
					/>

					<FormControl fullWidth variant="outlined" className={classes.input}>
						<InputLabel id="province-label">{t('common:province')}</InputLabel>

						<Select
							labelId="province-label"
							id="province"
							value={school.province}
							label={t('common:province')}
							onChange={(ev) =>
								setSchool((current) => {
									return { ...current, province: ev.target.value as Province };
								})
							}
						>
							{(provinces as any as string[]).sort(sort()).map((el) => {
								return (
									<MenuItem key={`province-${el}`} value={el}>
										{el}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>

					<InputContainer title={t('schools:level')}>
						{Object.keys(levels).map((key) => {
							const level = key as keyof typeof levels;
							return (
								<Box className={classes.subTree} key={`level-${level}`}>
									<FormControlLabel
										label={level}
										control={<Checkbox checked={hasLevel(school, level)} onChange={() => toggleLevel(level)} />}
									/>

									{levels[level].length > 0 && (
										<Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
											{levels[level].map((grade) => {
												return (
													<FormControlLabel
														key={`level-${level}-grade-${grade}`}
														label={grade}
														control={
															<Checkbox checked={hasGrade(school, grade)} onChange={() => toggleLevel(level, grade)} />
														}
													/>
												);
											})}
										</Box>
									)}

									{levels[level].length === 0 && (
										<Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
											<FormControlLabel
												disabled={true}
												label={t('common:empty')}
												control={<Checkbox />}
												style={{ opacity: 0 }}
											/>
										</Box>
									)}
								</Box>
							);
						})}
					</InputContainer>

					<InputContainer title={t('schools:curriculum')}>
						{curriculums.map((curriculum) => {
							return (
								<Box className={classes.subTree} key={`curriculum-${curriculum}`}>
									<Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
										<FormControlLabel
											key={`curriculum-${curriculum.name}`}
											label={curriculum.name}
											control={
												<Checkbox
													checked={school.curriculum.includes(curriculum.name)}
													onChange={() => toggleCurriculum(curriculum.name)}
												/>
											}
										/>
									</Box>
								</Box>
							);
						})}
					</InputContainer>

					<InputContainer title={t('schools:teachers')} disabled={newSchool}>
						<Box className={classes.extendedIcon}>
							<AddIcon aria-label="add" onClick={() => setShowTeacherModal(true)} color={'primary'} />
							<RefreshIcon aria-label="refresh" onClick={() => setShowRefreshModal(true)} color={'action'} />
						</Box>
						{newSchool && (
							<Box className={classes.createSchoolMessageContainer}>
								<Typography variant="caption">{t('schools:firstCreateSchoolMessage')}</Typography>
							</Box>
						)}

						<UserTable
							paper={false}
							users={users}
							onEdit={{
								action: {
									'aria-label': t('common:edit'),
									icon: EditIcon,
									title: t('common:edit'),
								},
								callback: setShowTeacherModal,
							}}
							disabled={newSchool}
							loading={loading}
							refetch={refetch}
							onSelect={(user) => setShowTeacherModal(user)}
						/>
					</InputContainer>

					<Button
						variant="contained"
						color="primary"
						size="large"
						className={classes.button}
						startIcon={<SaveIcon />}
						onClick={() => setShowSavingConfirmation(true)}
						disabled={!edited}
					>
						{t('common:save')}
					</Button>
				</FormGroup>

				{!loading && success && (
					<Alert
						message={t('common:success')}
						show={success}
						type={AlertsTypes.success}
						onClose={() => !schoolCreated && setSuccess(false)}
					/>
				)}
				{!loading && error && (
					<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />
				)}
			</PaperContainer>

			<Modal open={!!showTeacherModal} onClose={() => {}} className={classes.modal}>
				<PaperContainer className={classes.modalChild}>
					<UserEditionForm
						editingSelf={false}
						id={isUser(showTeacherModal) ? showTeacherModal._id || 'new' : 'new'}
						useDisableEdit={false}
						withPasswordReset={false}
						withDelete={true}
						onEdition={onTeacherEdition}
						isModal={true}
						type={['teacher', 'teacherAdmin']}
						withStatus={false}
						schoolHubspotId={school.hubspotId}
					/>
				</PaperContainer>
			</Modal>

			{showRefreshModal && (
				<ConfirmationDialog
					title={t('schools:syncTitle')}
					description={t('schools:syncText')}
					onClose={(confirmed) => (!confirmed ? setShowRefreshModal(false) : refresh())}
					loading={loading}
				/>
			)}

			{showSavingConfirmation && (
				<ConfirmationDialog
					title={t('users:confirmTeachersSaveTitle')}
					description={t('users:confirmTeachersSaveDescription')}
					onClose={(confirmed) => (!confirmed ? setShowSavingConfirmation(false) : save())}
					loading={loading}
				/>
			)}
		</ScreenContainer>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		input: {
			// flexGrow: 1,
			marginBottom: theme.spacing(2),
		},
		large: {
			width: theme.spacing(10),
			height: theme.spacing(10),
			margin: theme.spacing(4),
		},
		button: {
			margin: theme.spacing(1),
		},
		text: {
			marginTop: theme.spacing(2),
		},
		subTree: {
			display: 'inline-block',
		},
		createSchoolMessageContainer: {
			padding: theme.spacing(2),
		},
		editSchoolNameContainer: {
			marginBottom: theme.spacing(2),
		},
		editSchoolName: {
			color: theme.palette.text.hint,
		},
		extendedIcon: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			'&:hover': {
				cursor: 'pointer',
			},
		},
		modal: {
			width: '100%',
			height: '100%',
			maxWidth: '1200px',
			margin: 'auto',
			marginTop: theme.spacing(2),
		},
		modalChild: {
			padding: theme.spacing(2),
		},
		marginTop: {
			marginTop: theme.spacing(2),
		},
		buttonContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
			gap: theme.spacing(1),
		},
		errorsContainer: {
			overflow: 'auto',
		},
		errorContainer: {
			width: '100%',
			marginBottom: theme.spacing(2),
		},
	})
);

export default SchoolEdition;

function hasLevel(school: EditSchool, level: string) {
	return school.levels.find((schoolLevel) => {
		return schoolLevel.split('|')[0] === level;
	})
		? true
		: false;
}

function hasGrade(school: EditSchool, grade: string) {
	return school.levels.find((schoolLevel) => {
		const split = schoolLevel.split('|');
		return !!split[1] && split[1] === grade;
	})
		? true
		: false;
}
