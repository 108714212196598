import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LinearProgress from '@material-ui/core/LinearProgress';
import RefreshIcon from '@material-ui/icons/Refresh';

import Alert, { AlertsTypes } from 'components/Alert';
import SchoolList from 'components/SchoolList';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';
import useSchools from 'hooks/useSchools';
import { SchoolError, refreshSchools } from 'lib/models/schools';

import ConfirmationDialog from 'components/ConfirmationDialog';
import { Typography, createStyles, makeStyles } from '@material-ui/core';

function Schools() {
	const { t } = useTranslation();

	const styles = useStyles();

	const { loading, schools, setLoading, refetch } = useSchools();

	const [showRefreshModal, setShowRefreshModal] = useState(false);
	const [errors, setErrors] = useState<SchoolError[]>([]);

	const [error, setError] = useState('');

	const history = useHistory();

	const onClose = useCallback(
		async (confirmed: boolean) => {
			if (confirmed) {
				try {
					setErrors([]);
					setLoading(true);
					// If we wan't to search for new schools we need to pass both in 0
					const { errors: withErrors } = await refreshSchools();
					if (withErrors.length) {
						setErrors(withErrors);
					}
				} catch (error) {
					console.warn(error);
				}
				refetch();
			}
			setShowRefreshModal(false);
		},
		[refetch, setLoading]
	);

	return (
		<ScreenContainer title={t('general:school_plural')}>
			<CreateIcon onCreate={() => history.push('/schools/new')} />
			<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />

			{!!loading && <LinearProgress />}

			<SchoolList
				schools={schools}
				loading={loading}
				customAction={{
					'aria-label': 'common:refresh',
					icon: RefreshIcon,
					title: t('common:refresh'),
					onClick: () => setShowRefreshModal(true),
				}}
			/>

			{!!errors.length && (
				<ConfirmationDialog
					title={t('schools:syncSchoolsErrorsTitle')}
					onClose={() => setErrors([])}
					loading={false}
					warningMessage={t('schools:syncAllErrorWarningText')}
				>
					<div className={styles.errorsContainer}>
						{errors.map((error) => {
							return (
								<div className={styles.errorContainer}>
									<Typography key={error.id}>
										{error.name} ({error.id}): {error.error}
									</Typography>
								</div>
							);
						})}
					</div>
				</ConfirmationDialog>
			)}

			{showRefreshModal && (
				<ConfirmationDialog
					title={t('schools:syncSchoolsTitle')}
					description={t('schools:syncSchoolsText')}
					onClose={onClose}
					loading={loading}
					warningMessage={t('schools:syncAllWarningText')}
				/>
			)}
		</ScreenContainer>
	);
}

export default Schools;

const useStyles = makeStyles((theme) =>
	createStyles({
		errorsContainer: {
			overflow: 'auto',
		},
		errorContainer: {
			width: '100%',
			marginBottom: theme.spacing(2),
		},
	})
);
