const lists = {
	studentsAmount: 'Number of students',
	itemsAmount: 'Items Amount',
	deleteTitle: 'Delete list',
	exportTitle: 'Export lists',
	exportDescription: "Are you sure you wan't to export the selected lists?",
	items: 'Books',
	onDeleteTitle: 'Remove',
	onSelectedTitle: 'Add',
	downloadAll: 'Download all',
};

export default lists;
