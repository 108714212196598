import Alert, { AlertsTypes } from 'components/Alert';
import ConfirmationDialog from 'components/ConfirmationDialog';
import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';
import ModalForm from 'components/ModalForm';
import SeriesList from 'components/SeriesList';
import useSeries from 'hooks/useSeries';
import { askUpdateSerie, createSerie, deleteSerie, updateSerie } from 'lib/models/series';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function Series() {
	const { t } = useTranslation();

	const { series, loading, refetch, setLoading, count } = useSeries();
	const [open, setOpen] = useState(false);
	const [serie, setSerie] = useState<SubjectType>({ name: '', id: '' });
	const [modifyQuantity, setModifyQuantity] = useState(0);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [deleted, setDeleted] = useState(false);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);

	const edit = async (serie: SerieType) => {
		const { success, items } = await askUpdateSerie(serie);
		if (success) {
			setModifyQuantity(items);
			setSerie(serie);
			setOpen(true);
		}
	};

	const onDelete = async (serie: SerieType) => {
		const { success, items } = await askUpdateSerie(serie);
		if (success) {
			setModifyQuantity(items);
			setConfirmDeleteModal(true);
			setSerie(serie);
		}
	};

	const onUpdate = async (value: string) => {
		setSerie((prev) => {
			return { ...prev, name: value };
		});
	};

	const confirmDelete = async (confirm: boolean) => {
		if (confirm) {
			try {
				await deleteSerie(serie);
				setDeleted(true);
				refetch();
			} catch (error: any) {
				console.error(error);
				setError(error.message || error);
			}
		}
		setSerie({ name: '', id: '' });
		setConfirmDeleteModal(false);

		resetAlert();
	};

	const save = async () => {
		setLoading(true);
		try {
			serie._id ? await updateSerie(serie) : await createSerie(serie);
			setSuccess(true);
			refetch();
			closeModal();
		} catch (error) {
			console.error(error);
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
		resetAlert();
	};

	const resetAlert = () => {
		setTimeout(() => {
			setSuccess(false);
			setError('');
			setDeleted(false);
		}, 3000);
	};

	const closeModal = () => {
		setOpen(false);
		setSerie({ name: '', id: '' });
		setModifyQuantity(0);
	};

	const dialogDescription =
		modifyQuantity > 0 ? t('series:delete_serie_description', { quantity: modifyQuantity }) : undefined;

	return (
		<ScreenContainer title={t('series:serie_plural')}>
			<CreateIcon
				onCreate={() => {
					setOpen(true);
				}}
			/>

			<SeriesList series={series} edit={edit} onDelete={onDelete} count={count} refetch={refetch} />

			{confirmDeleteModal && (
				<ConfirmationDialog onClose={confirmDelete} title={t('series:delete_serie')} description={dialogDescription} />
			)}

			{open && (
				<ModalForm
					title={serie.id ? t('series:edit_serie') : t('series:add_serie')}
					item={serie}
					onUpdate={onUpdate}
					onSubmit={save}
					onCancel={closeModal}
					description={
						modifyQuantity > 0 ? t('series:edit_serie_description', { quantity: modifyQuantity }) : undefined
					}
				/>
			)}

			{!loading && success && (
				<Alert
					message={t('common:success')}
					show={success}
					type={AlertsTypes.success}
					onClose={() => setSuccess(false)}
				/>
			)}

			{!loading && deleted && (
				<Alert
					message={t('common:deleted')}
					show={deleted}
					type={AlertsTypes.success}
					onClose={() => setDeleted(false)}
				/>
			)}

			{!loading && error && (
				<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />
			)}
		</ScreenContainer>
	);
}

export default Series;
