import { useTranslation } from 'react-i18next';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';
import { useCallback, useEffect, useState } from 'react';
import ModalForm from 'components/ModalForm';
import {
	askDeleteCurriculums,
	createCurriculums,
	deleteCurriculums,
	fetchCurriculums,
	updateCurriculums,
} from 'lib/models/curriculums';
import Alert, { AlertsTypes } from 'components/Alert';
import ConfirmationDialog from 'components/ConfirmationDialog';
import CurriculumsList from 'components/CurriculumsList';
import { useHistory } from 'react-router-dom';
import { Box, DialogContentText } from '@material-ui/core';

function Curriculums() {
	const history = useHistory();
	const { t } = useTranslation();

	const [curriculums, setCurriculums] = useState<CurriculumType[]>([]);

	const [curriculum, setComponent] = useState<CurriculumType>({ name: '', id: '' });
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [modifyQuantity, setModifyQuantity] = useState(0);
	const [deleted, setDeleted] = useState(false);

	const [error, setError] = useState('');

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchCurriculums();
		if (response) {
			setCurriculums(response);
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	const edit = async (curriculum: CurriculumType) => {
		const { success, items } = await askDeleteCurriculums(curriculum);
		if (success) {
			setModifyQuantity(items);
			setComponent(curriculum);
			setOpen(true);
		}
	};

	const onDelete = async (curriculum: CurriculumType) => {
		const { success, items } = await askDeleteCurriculums(curriculum);
		if (success) {
			setModifyQuantity(items);
			setConfirmDeleteModal(true);
			setComponent(curriculum);
		}
	};

	const save = async () => {
		setLoading(true);
		try {
			curriculum._id ? await updateCurriculums(curriculum) : await createCurriculums(curriculum);
			setSuccess(true);
			refetch();
			closeModal();
		} catch (error) {
			console.error(error);
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
		resetAlert();
	};

	const onUpdate = async (value: string) => {
		setComponent((prev) => {
			return { ...prev, name: value };
		});
	};

	const confirmDelete = async (confirm: boolean) => {
		if (confirm) {
			try {
				await deleteCurriculums(curriculum);
				setDeleted(true);
				setConfirmDeleteModal(false);
				refetch();
			} catch (error: any) {
				console.error(error);
				setError(error.message || error);
			}
		} else {
			setComponent({ name: '', id: '' });
			setConfirmDeleteModal(false);
		}
		resetAlert();
	};

	const closeModal = () => {
		setOpen(false);
		setComponent({ name: '', id: '' });
		setModifyQuantity(0);
	};

	const resetAlert = () => {
		setTimeout(() => {
			setSuccess(false);
			setError('');
			setDeleted(false);
		}, 3000);
	};

	const dialogDescription =
		modifyQuantity > 0
			? t('curriculums:existent_items_on_delete_description')
			: t('curriculums:delete_component_description', { quantity: modifyQuantity });
	return (
		<ScreenContainer title={t('general:curriculum_plural')}>
			<CreateIcon
				onCreate={() => {
					setOpen(true);
				}}
			/>

			<CurriculumsList curriculums={curriculums} edit={edit} onDelete={onDelete} />
			{confirmDeleteModal && modifyQuantity === 0 && (
				<ConfirmationDialog
					onClose={confirmDelete}
					title={t('curriculums:delete_certificate')}
					description={dialogDescription}
				/>
			)}

			{confirmDeleteModal && modifyQuantity > 0 && (
				<ConfirmationDialog
					confirmButton={t('common:cancel')}
					onClose={() => {
						history.push(`/items?curriculum=${curriculum.name}`);
					}}
					hideCancel={true}
					title={t('system:errorTryingDelete')}
				>
					<Box>
						<DialogContentText>{t('curriculums:existent_items_on_delete_title')}.</DialogContentText>
						<DialogContentText>{dialogDescription}</DialogContentText>
					</Box>
				</ConfirmationDialog>
			)}

			{open && (
				<ModalForm
					title={curriculum.id ? t('curriculums:edit_curriculum') : t('curriculums:add_curriculum')}
					item={curriculum}
					onUpdate={onUpdate}
					onSubmit={save}
					onCancel={closeModal}
					description={
						modifyQuantity > 0 ? t('curriculums:edit_curriculum_description', { quantity: modifyQuantity }) : undefined
					}
				/>
			)}
			{!loading && success && (
				<Alert
					message={t('common:success')}
					show={success}
					type={AlertsTypes.success}
					onClose={() => setSuccess(false)}
				/>
			)}

			{!loading && deleted && (
				<Alert
					message={t('common:deleted')}
					show={deleted}
					type={AlertsTypes.success}
					onClose={() => setDeleted(false)}
				/>
			)}

			{!loading && error && (
				<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />
			)}
		</ScreenContainer>
	);
}

export default Curriculums;
