import { useReducer, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import {
	Button,
	createStyles,
	DialogActions,
	FormHelperText,
	IconButton,
	LinearProgress,
	makeStyles,
} from '@material-ui/core';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { useTranslation } from 'react-i18next';

import { reducer, defaultState, isImportErrors } from './reducer';

import { getStringFromData } from 'lib/helpers';
import { fetchErrorFile, syncData } from 'lib/models/items';
import useSyncStatus from './useSyncStatus';

type SyncProps<T extends Object = any> = {
	openDialog: boolean;
	onCancel(): void;
	onEnded(response: ParseCsvFileResult<T> | null): void;
	warning?: string;
};

function ItemsSync({ openDialog, onCancel, onEnded, warning: warningMessage }: SyncProps) {
	const { t } = useTranslation();

	const classes = useStyles();

	const { status, lastRun, loading } = useSyncStatus();

	const [{ syncing, error, errors, warning, imported, response }, dispatch] = useReducer(reducer, defaultState);

	const reset = useCallback(function cancel() {
		dispatch({ type: 'Reset' });
	}, []);

	const cancel = useCallback(
		function cancel() {
			reset();
			onCancel();
		},
		[reset, onCancel]
	);

	const onSync = useCallback(async () => {
		try {
			dispatch({ type: 'SetSyncing', payload: true });
			const importRes = await syncData();
			if (importRes.success) {
				dispatch({ type: 'SetResponse', payload: importRes });
				dispatch({ type: 'SetImported', payload: true });
			} else {
				dispatch({ type: 'SetImported', payload: true });
				if (isImportErrors(importRes.errors)) {
					dispatch({ type: 'SetErrors', payload: importRes.errors });
				}
				dispatch({
					type: 'SetError',
					payload: !!importRes.error
						? importRes.error
						: t('common:followingElementsWithError', {
								key: 'isbn',
								elements: importRes.errors
									.map((error) => (typeof error === 'string' ? error : error._id || getStringFromData(error)))
									.join(','),
								error: importRes.error || 'unknown error',
						  }),
				});
			}
			dispatch({ type: 'SetWarning', payload: importRes.warning });
		} catch (err) {
			const error = err as unknown as any;
			console.warn(error);
			dispatch({ type: 'SetImported', payload: true });
			dispatch({ type: 'SetError', payload: error && error.message ? error.message : `${error || 'unknown error'}` });
		}
	}, [t]);

	const ended = useCallback(() => {
		dispatch({ type: 'Reset' });
		onEnded(response);
	}, [onEnded, response]);

	return (
		<Dialog open={openDialog} onClose={() => cancel()} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">{t('items:sync')}</DialogTitle>

			<DialogContent>
				{!!warning && <FormHelperText className={classes.warningMessage}>{warning}</FormHelperText>}
				{!!error && <FormHelperText className={classes.errorMessage}>{error}</FormHelperText>}
				{errors.map((error, index) => (
					<FormHelperText key={`error-${index}`} className={classes.errorMessage}>
						isbn
						{error.element.isbn || `position  (${index + 1})`}: {error.error}
					</FormHelperText>
				))}
				{imported && <FormHelperText className={classes.successMessage}>{t('items:syncSuccess')}</FormHelperText>}

				{syncing && <LinearProgress variant={'indeterminate'} />}

				<div>
					<strong>{t('common:status')}:</strong> {t(`common:${status}`)}
				</div>

				<div>
					<strong>{t('common:lastSuccessfulRun')}:</strong> {!!lastRun ? `${new Date(lastRun).toISOString()}` : '- - -'}
				</div>

				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Button
						style={{ marginTop: '16px', marginBottom: '16px' }}
						variant="contained"
						onClick={onSync}
						disabled={status === 'running' || loading || syncing}
					>
						{t('items:syncStart')}
					</Button>

					<Button style={{ marginTop: '16px', marginBottom: '16px' }} variant="contained" onClick={fetchErrorFile}>
						Download latest error log file
					</Button>
				</div>
				{!!warningMessage && <FormHelperText className={classes.warningMessage}>{warningMessage}</FormHelperText>}
			</DialogContent>

			<DialogActions>
				{!imported && (
					<IconButton onClick={cancel} disabled={imported || syncing}>
						<HighlightOffIcon fontSize="large" />
					</IconButton>
				)}
				{imported && (
					<IconButton onClick={ended}>
						<CheckCircleOutlineIcon fontSize="large" />
					</IconButton>
				)}
			</DialogActions>
		</Dialog>
	);
}

export default ItemsSync;

const useStyles = makeStyles((theme) =>
	createStyles({
		errorMessage: {
			alignItems: 'center',
			display: 'flex',
			color: theme.palette.error.main,
			fontFamily: theme.typography.fontFamily,
			fontSize: theme.typography.fontSize,
			marginBottom: theme.spacing(1),
		},
		warningMessage: {
			alignItems: 'center',
			display: 'flex',
			color: theme.palette.warning.main,
			fontFamily: theme.typography.fontFamily,
			fontSize: theme.typography.fontSize,
			marginBottom: theme.spacing(1),
		},
		successMessage: {
			alignItems: 'center',
			display: 'flex',
			color: theme.palette.success.main,
			fontFamily: theme.typography.fontFamily,
			fontSize: theme.typography.fontSize,
			marginBottom: theme.spacing(1),
		},
	})
);
