import { syncStatus } from 'lib/models/items';
import { useCallback, useEffect, useState } from 'react';

let timer: any = null;

function useSyncStatus() {
	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState<'idle' | 'running'>('idle');
	const [lastRun, setLastRun] = useState<Date | null>(null);

	const refetch = useCallback(async () => {
		const response = await syncStatus();
		setStatus(response.status);
		setLastRun(response.lastRun);
		setLoading(false);
	}, []);

	useEffect(() => {
		if (!timer) {
			timer = setInterval(() => {
				refetch();
			}, 500);
		}

		return () => {
			if (timer) {
				clearInterval(timer);
				timer = null;
			}
		};
	}, [refetch]);

	return {
		status,
		lastRun,
		loading,
		refetch,
	};
}

export default useSyncStatus;
