// import ComponentsTable from './ComponentTable';

import CertificatesTable from './CertificatesTable';

type Props = {
	certificates: CertificateType[];
	edit: (component: CertificateType) => void;
	onDelete: (component: CertificateType) => void;
};
function CertificatesList(props: Props) {
	return <CertificatesTable {...props} />;
}

export default CertificatesList;
