const lists = {
	studentsAmount: 'Cant. Estudiantes',
	itemsAmount: 'Cant. Items',
	deleteTitle: 'Eliminar lista',
	exportTitle: 'Exportar listas',
	exportDescription: '¿Está seguro que desea exportar las listas seleccionadas?',
	items: 'Libros',
	onDeleteTitle: 'Quitar',
	onSelectedTitle: 'Agregar',
	downloadAll: 'Descargar todas',
};

export default lists;
