import { API_DOMAIN, ITEM_TYPE_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function fetchItemTypes() {
	try {
		const response = await api.get<ItemType[]>(`${API_DOMAIN}${ITEM_TYPE_URL}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function updateItemType(itemTypes: ItemType[]): Promise<ItemType[] | null> {
	const url = `${API_DOMAIN}${ITEM_TYPE_URL}`;

	try {
		const response = await api.patch<ItemType[]>(url, itemTypes);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}
