import { API_DOMAIN, LISTS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';
import { Moment } from 'moment';
import { ListStatus } from 'teachers-types';

export async function fetchLists(): Promise<List[]> {
	try {
		const response = await api.get<List[]>(`${API_DOMAIN}${LISTS_URL}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export type SearchListProps = {
	schoolHubspotId?: string | null;
	status?: ListStatus | null;
	level?: string | null;
	text?: string | null;
	from?: Moment | null;
	to?: Moment | null;
};

export async function searchLists(props: SearchListProps): Promise<List[]> {
	try {
		let filter: any = {};
		if (props.schoolHubspotId) {
			filter.schoolHubspotId = props.schoolHubspotId;
		}
		if (props.status) {
			filter.status = props.status;
		}
		if (props.level) {
			filter.level = props.level;
		}
		if (props.text) {
			filter.$or = [
				{ name: { $regex: props.text, $options: 'i' } },
				{ message: { $regex: props.text, $options: 'i' } },
			];
		}
		if (props.from) {
			filter.updatedAt = {
				$gte: props.from.toDate(),
			};
		}
		if (props.to) {
			filter.updatedAt = {
				...(filter.updatedAt || {}),
				$lte: props.to.toDate(),
			};
		}
		const response = await api.get<List[]>(`${API_DOMAIN}${LISTS_URL}/search`, {
			params: {
				filter,
			},
		});

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function fetchList(id: string): Promise<List | null> {
	try {
		const response = await api.get<List | null>(`${API_DOMAIN}${LISTS_URL}/${id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateList(list: List): Promise<List | List[]> {
	if (!list._id) {
		delete list._id;
	}

	const url = list._id ? `${API_DOMAIN}${LISTS_URL}/${list._id}` : `${API_DOMAIN}${LISTS_URL}`;

	const response = await (list._id ? api.patch<List>(url, list) : api.post<List[]>(url, list));

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || list;
}

export async function deleteList(list: List): Promise<List> {
	const url = `${API_DOMAIN}${LISTS_URL}/${list._id}`;

	const response = await api.delete<List>(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || list;
}
