import { API_DOMAIN, CERTIFICATES_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function fetchCertificates(): Promise<CertificateType[]> {
	try {
		const response = await api.get<CertificateType[]>(`${API_DOMAIN}${CERTIFICATES_URL}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}
export async function createCertificate(certificate: CertificateType): Promise<CertificateType | null> {
	try {
		const data = { ...certificate, name: certificate.name.trim() };
		const response = await api.post<CertificateType>(`${API_DOMAIN}${CERTIFICATES_URL}`, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateCertificate(certificate: CertificateType): Promise<CertificateType | null> {
	if (!certificate._id) {
		delete certificate._id;
	}
	const data = { ...certificate, name: certificate.name.trim() };

	const url = `${API_DOMAIN}${CERTIFICATES_URL}/${certificate._id}`;

	try {
		const response = await api.patch<CertificateType>(url, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function deleteCertificate(certificate: CertificateType): Promise<{ success: boolean }> {
	const url = `${API_DOMAIN}${CERTIFICATES_URL}/${certificate._id}`;

	try {
		const response = await api.delete(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return { success: true };
	} catch (error) {
		console.error(error);
		return { success: false };
	}
}

export async function askUpdateCertificate(certificate: CertificateType): Promise<{ items: number; success: boolean }> {
	const url = `${API_DOMAIN}${CERTIFICATES_URL}/ask-quantity/${certificate._id}`;

	try {
		const response = await api.get<{ items: number; success: boolean }>(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return { items: 0, success: false };
	}
}
