const subjects = {
	subject_plural: 'Materias',
	add_subject: 'Agregar materia',
	edit_subject: 'Editar materia',
	edit_subject_description: 'Se actualizará la materia asignada de {{quantity}} libros.',
	delete_subject: 'Borrar la materia seleccionada?',
	delete_subject_description: 'La materia será eliminada de {{quantity}} items',
	existent_items_on_delete_title: 'Existen elementos asignados con esta materia',
	existent_items_on_delete_description: 'Modifica la materia de los items antes de eliminar la materia.',
} as const;

export default subjects;
