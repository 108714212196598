const curriculums = {
	curriculum_plural: 'Curriculums',
	add_curriculum: 'Add curriculum',
	edit_curriculum: 'Edit curriculum',
	edit_curriculum_description: 'The curriculum of {{quantity}} books will be updated',
	delete_curriculum: 'Delete selected curriculum?',
	delete_curriculum_description: 'The curriculum will be removed from {{quantity}} items',

	existent_items_on_delete_title: 'There are items assigned with this curriculum',
	existent_items_on_delete_description: 'Modify the items with this curriculum before deleting the curriculum.',
} as const;

export default curriculums;
