import CurriculumsTable from './CurriculumsTable';

type Props = {
	curriculums: CurriculumType[];
	edit: (component: CurriculumType) => void;
	onDelete: (component: CurriculumType) => void;
};

function CurriculumsList(props: Props) {
	return <CurriculumsTable {...props} />;
}

export default CurriculumsList;
