import { fetchCertificates } from 'lib/models/certificates';
import { fetchCurriculums } from 'lib/models/curriculums';
import { fetchCycles } from 'lib/models/cycles';
import { fetchFormats } from 'lib/models/formats';
import { fetchGenres } from 'lib/models/genres';
import { fetchSeries } from 'lib/models/series';
import { fetchSubjects } from 'lib/models/subjects';
import { useEffect, useState } from 'react';

import {
	academicLevels,
	approaches,
	cefrLevels,
	itemTypes,
	languages,
	levels,
	workloads,
	curriculums,
	components,
	subjects,
	series,
	certificates,
	genres,
	formats,
	cycles,
} from 'teachers-types';

import { levelsFilters } from 'types/constants';
//import { fetchCertificates } from 'lib/models/certificates';
//import { fetchComponents } from 'lib/models/components';
//import { fetchCurriculums } from 'lib/models/curriculums';
//import { fetchSubjects } from 'lib/models/subjects';
//import { fetchSeriesForFilter } from 'lib/models/series';
export type ParentValue = keyof typeof levels;

const valuesGetter: { [key in string]: (parentValue?: ParentValue) => Promise<string[]> } = {
	approach: async () => approaches as any as string[],
	certificate: async () => {
		const fetchedCertificates = await fetchCertificates();
		const additionalCertificates = certificates as any as string[];
		return [...fetchedCertificates.map((c) => c.name), ...additionalCertificates];
	},
	//component: async () => components as any as string[],
	component: async () => {
		const component = await fetchCurriculums();
		const additionalComponents = components as any as string[];
		return [...component.map((c) => c.name), ...additionalComponents];
	},
	curriculum: async () => {
		const curriculum = await fetchCurriculums();
		const additionalCurriculums = curriculums as any as string[];
		return [...curriculum.map((c) => c.name), ...additionalCurriculums];
	},
	genre: async () => {
		const genre = await fetchGenres();
		const additionalGenres = genres as any as string[];
		return [...genre.map((c) => c.name), ...additionalGenres];
	},
	format: async () => {
		const format = await fetchFormats();
		const additionalFormats = formats as any as string[];
		return [...format.map((c) => c.name), ...additionalFormats];
	},
	cycle: async () => {
		const cycle = await fetchCycles();
		const additionalCycles = cycles as any as string[];
		return [...cycle.map((c) => c.name), ...additionalCycles];
	},
	isRecommended: async () => ['true', 'false'],
	isFeatured: async () => ['true', 'false'],
	language: async () => languages as any as string[],
	//serie: async () => series as any as string[],
	serie: async () => {
		const serie = await fetchSeries();
		const additionalSerie = series as any as string[];
		return [...serie.data.map((c) => c.name), ...additionalSerie];
	},
	level: async () => levelsFilters as any as string[],
	subject: async () => {
		const subject = await fetchSubjects();
		const additionalSubjects = subjects as any as string[];
		return [...subject.map((c) => c.name), ...additionalSubjects];
	},
	workload: async () => workloads as any as string[],
	academicLevel: async () => academicLevels as any as string[],
	cefrLevel: async () => cefrLevels as any as string[],
	grade: async (parent?: keyof typeof levels) =>
		(parent && levels[parent] && levels[parent].length ? levels[parent] : []) as unknown as string[],
	showroomType: async () => ['pdf', 'website'],
	text: async () => [] as string[],
	type: async () => itemTypes as any as string[],
} as const;

export default function useValues<T extends string>(key: T, parentValue?: ParentValue) {
	const [currentValues, setValues] = useState<string[]>([] as string[]);

	useEffect(() => {
		async function sync() {
			const data = valuesGetter[key] ? await valuesGetter[key]!(parentValue) : [];
			setValues(data);
		}
		sync();
	}, [key, parentValue]);

	return currentValues;
}
