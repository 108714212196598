const cycles = {
	cycle_plural: 'Cycles',
	add_cycle: 'Add cycle',
	edit_cycle: 'Edit cycle',
	edit_cycle_description: 'The cycle of {{quantity}} books will be updated',
	delete_cycle: 'Delete selected cycle?',
	delete_cycle_description: 'The cycle will be removed from {{quantity}} items',

	existent_items_on_delete_title: 'There are items assigned with this cycle',
	existent_items_on_delete_description: 'Modify the items with this cycle before deleting the cycle.',
} as const;

export default cycles;
