// import ComponentsTable from './ComponentTable';

import GenresTable from './GenresTable';

type Props = {
	genres: GenreType[];
	edit: (component: GenreType) => void;
	onDelete: (component: GenreType) => void;
};
function GenresList(props: Props) {
	return <GenresTable {...props} />;
}

export default GenresList;
