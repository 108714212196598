// import ComponentsTable from './ComponentTable';

import LevelMapsTable from './LevelMaps';

type Props = {
	levelMaps: LevelMapType[];
	edit: (component: LevelMapType) => void;
	onDelete: (component: LevelMapType) => void;
};
function LevelMapsList(props: Props) {
	return <LevelMapsTable {...props} />;
}

export default LevelMapsList;
