const common = {
	// Common
	username: 'Nombre de usuario',
	password: 'Contraseña',
	firstName: 'Nombre',
	lastName: 'Apellido',
	email: 'Email',
	documentNumber: 'Número de documento',
	document: 'Documento',
	fileNumber: 'Legajo',
	dateFrom: 'Fecha desde',
	dateTo: 'Fecha hasta',
	accept: 'Aceptar',
	action: 'Acción',
	actions: 'Acciones',
	add: 'Agregar',
	back: 'Volver',
	cancel: 'Cancelar',
	close: 'Cerrar',
	create: 'Crear',
	delete: 'Eliminar',
	duration: 'Duración',
	edit: 'Editar',
	filter: 'Filtrar',
	filtered: 'filtrados',
	general: 'General',
	go: 'Ir',
	hide: 'Ocultar',
	list: 'Listado',
	new: 'Nuevo',
	next: 'Siguiente',
	previous: 'Anterior',
	reload: 'Recargar',
	remove: 'Quitar',
	save: 'Guardar',
	search: 'Buscar',
	select: 'Seleccionar',
	selected: 'seleccionados',
	selectDate: 'Seleccionar fecha',
	selectYear: 'Seleccionar año',
	selectMonth: 'Seleccionar mes',
	selectDates: 'Elegir fechas',
	selectEmployee: 'Elegir un empleado',
	to: 'a',
	upload: 'Subir',
	fieldMessage: 'Completar el campo ({{field}})',
	requiredField: '({{field}}) es requerido',
	invalidValue: 'Valor inválido ingresado',
	invalidType: 'Tipo incorrecto ingresado',
	sunday: 'Domingo',
	monday: 'Lunes',
	tuesday: 'Martes',
	wednesday: 'Miércoles',
	thursday: 'Jueves',
	friday: 'Viernes',
	saturday: 'Sábado',
	month: 'mes',
	year: 'año',
	week: 'semana',
	week_plural: 'semanas',
	day: 'día',
	day_plural: 'días',
	hour: 'hora',
	hour_plural: 'horas',
	minute: 'minuto',
	minute_plural: 'minutos',
	success: 'Se guardó correctamente',
	download: 'Descargar',
	process: 'Procesar',
	uploadError: 'Hubo un problema al intentar procesar el archivo, de ser necesario limpia los datos y volvé a intentar',
	uploadSuccess: 'Se procesó el archivo correntamente',
	densePadding: 'Fila densa',
	resetUpload: 'Limpiar datos',
	month_1: 'Enero',
	month_2: 'Febrero',
	month_3: 'Marzo',
	month_4: 'Abril',
	month_5: 'Mayo',
	month_6: 'Junio',
	month_7: 'Julio',
	month_8: 'Agosto',
	month_9: 'Septiembre',
	month_10: 'Octubre',
	month_11: 'Noviembre',
	month_12: 'Diciembre',
	vacations: 'Vacaciones',
	notImplemented: 'Funcionalidad no implementada',
	rowsPerPage: 'Filas por página',
	sendEmail: 'Enviar email',
	description: 'Descripción',
	findAnAgent: 'Busque un empleado ...',
	pickAnAgent: 'Elegir un empleado',
	pickDates: 'Elegir fechas',
	setObservations: 'Agregar observaciones',
	cuil: 'CUIL',
	address: 'Dirección',
	telephone: 'Teléfono',
	alternateEmail: 'Email Alternativo',
	multipleDates: 'Multiple fechas',
	days: 'Días',
	import: 'Importar',
	importingPleaseWait: 'Por favor espere, importando',
	all: 'Todo',
	amount: 'Cantidad',
	without: 'Sin {{element}}',
	more: 'Más',
	code: 'Código',
	name: 'Nombre',
	levels: 'Niveles',
	deleteTitle: 'Eliminación',
	deleteText: '¿Está seguro que desea eliminar al elemento seleccionado?',
	deleteText_plural: '¿Está seguro que desea eliminar a los elementos seleccionados?',
	loading: 'Cargando, por favor espere...',
	role: 'rol',
	role_admin: 'Administrador',
	role_panel: 'Usuario de panel',
	role_teacher: 'Docente',
	role_teacherAdmin: 'Docente (coordinador)',
	role_user: 'Sólo lectura',
	active: 'Activo',
	isactive: 'Activo',
	inactive: 'Inactivo',
	invited: 'Invitación enviada',
	selectCSVFile: 'Seleccionar archivo CSV',
	selectZIPFile: 'Seleccionar archivo ZIP',
	selectFile: 'Seleccionar archivo',
	fileError: 'Formato de archivo no soportado',
	importSuccess: 'Archivo importado correctamente',
	position: 'posición',
	googleMaps: 'Google maps',
	language: 'Idioma',
	spanish: 'español',
	english: 'inglés',
	valueFrom: 'Desde',
	valueTo: 'Hasta',
	$lte: 'Menor o igual a',
	$gte: 'Mayor o igual a',
	backOfficeDocumentationTitle: 'Documentación del panel',
	backOfficeDocumentationDescription: 'Link a documentación sobre el uso del panel',
	itDocumentationTitle: 'Documentación IT',
	itDocumentationDescription: 'Link a documentación sobre la configuración del servidor',
	welcome:
		'Bienvenido {{name}}, si necesitás ayuda con el uso del panel, o con alguna configuración del servidor, puedes acceder a la documentación con los links de abajo.',
	dates: 'Rango de fechas',
	ranges: 'Rangos',
	export: 'Exportar',
	error: 'error',
	idle: 'inactivo',
	running: 'corriendo',
	waiting: 'escuchando',
	withoutData: 'sin datos',
	tokenExpired: 'Tu sesión ha expirado, por favor ingrese nuevamente su usuario y contraseña',
	serverIsDown: 'El servidor se encuentra inactivo, por favor contacte al departamento de sistemas para más ayuda',
	historic: 'Histórico',
	followingElementsWithError:
		'Los siguientes elementos con la columna "{{key}}" ({{elements}}) no pudieron ser creados, error: {{error}}',
	update_inactive: 'Invitar',
	update_invited: 'Reenviar',
	update_again: 'Error, probar nuevamente',
	level: 'Nivel',
	school: 'Escuela',
	draft: 'Borrador',
	'pending approval': 'Pendiente de aprob.',
	approved: 'Aprobado (por admin. de escuela)',
	kelApproved: 'Aprobado (por Kel)',
	listMessage: 'Mensaje',
	status: 'Estado',
	exact: 'exacto',
	cancelDescription: '¿Está seguro que desea regresar a la pantalla de login?',
	message: 'Mensaje (enviado por {{email}})',
	noMessage: 'Sin mensaje',
	refresh: 'Actualizar',
	refreshWarning: 'Actualizar (no todos los elementos fueron obtenidos, por favor volver a actualizar)',
	province: 'Provincia',
	exampleFile: 'Descargar un archivo .csv de ejemplo',
	oldest: 'Fecha más antigua',
	createdAt: 'Creado',
	updatedAt: 'Actualizado',
	siteConfig: 'Configuración del sitio de docentes',
	instructiveURL: 'URL del PDF "instructivo"',
	sortBy: 'Ordenar por',
	groupBy: 'Agrupar por',
	image: 'Imágen',
	title: 'Título',
	archived: 'Archivado',
	amountRequired: 'La cantidad de estudiantes debe existir para aprobar una lista',
	approve: 'Aprobar',
	archive: 'Archivar',
	view: 'Ver',
	from: 'Desde',
	upTo: 'Hasta',
	date: 'Fecha',
	text: 'Texto',
	backgroundColor: 'Color de fondo',
	textColor: 'Color de texto',
	minValue: 'Valor mínimo',
	stockValues: 'Configurar mensajes de disponibilidad',
	stockDescription: `Para personalizar los mensajes mostrados en el sitio web según el stock disponible, es necesario ingresar un texto breve en inglés y un valor mínimo asociado. 
		El sistema seleccionará el mensaje adecuado basado en el nivel de inventario. Por ejemplo, si añades el mensaje 'Without stock' con un valor mínimo de 0, 'Last units' con un valor de 1, 
		y 'In stock' con un valor de 5, se mostrará 'Without stock' cuando no haya inventario, 'Last units' cuando el stock esté entre 1 y 5 unidades, y 'In stock' cuando haya más de 5 unidades.
		
		Si dejás el valor del campo de texto vacío, el mismo no se mostrará en el sitio.`,
	lastSuccessfulRun: 'Fecha última sincronización válida',
} as const;

export default common;
