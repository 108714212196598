import { API_DOMAIN, CYCLES_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function fetchCycles(): Promise<CycleType[]> {
	try {
		const response = await api.get<CycleType[]>(`${API_DOMAIN}${CYCLES_URL}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}
export async function createCycle(cycle: CycleType): Promise<CycleType | null> {
	try {
		const data = { ...cycle, name: cycle.name.trim() };
		const response = await api.post<CycleType>(`${API_DOMAIN}${CYCLES_URL}`, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateCycle(cycle: CycleType): Promise<CycleType | null> {
	if (!cycle._id) {
		delete cycle._id;
	}
	const data = { ...cycle, name: cycle.name.trim() };

	const url = `${API_DOMAIN}${CYCLES_URL}/${cycle._id}`;

	try {
		const response = await api.patch<CycleType>(url, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function deleteCycle(cycle: CycleType): Promise<{ success: boolean }> {
	const url = `${API_DOMAIN}${CYCLES_URL}/${cycle._id}`;

	try {
		const response = await api.delete(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return { success: true };
	} catch (error) {
		console.error(error);
		return { success: false };
	}
}

export async function askUpdateCycle(cycle: CycleType): Promise<{ items: number; success: boolean }> {
	const url = `${API_DOMAIN}${CYCLES_URL}/ask-quantity/${cycle._id}`;

	try {
		const response = await api.get<{ items: number; success: boolean }>(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return { items: 0, success: false };
	}
}
