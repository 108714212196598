const formats = {
	format_plural: 'Formats',
	add_format: 'Add format',
	edit_format: 'Edit format',
	edit_format_description: 'The format of {{quantity}} books will be updated',
	delete_format: 'Delete selected format?',
	delete_format_description: 'The format will be removed from {{quantity}} items',

	existent_items_on_delete_title: 'There are items assigned with this format',
	existent_items_on_delete_description: 'Modify the items with this format before deleting the format.',
} as const;

export default formats;
