import { API_DOMAIN, GENRES_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function fetchGenres(): Promise<GenreType[]> {
	try {
		const response = await api.get<GenreType[]>(`${API_DOMAIN}${GENRES_URL}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}
export async function createGenre(genre: GenreType): Promise<GenreType | null> {
	try {
		const data = { ...genre, name: genre.name.trim() };
		const response = await api.post<GenreType>(`${API_DOMAIN}${GENRES_URL}`, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateGenre(genre: GenreType): Promise<GenreType | null> {
	if (!genre._id) {
		delete genre._id;
	}
	const data = { ...genre, name: genre.name.trim() };

	const url = `${API_DOMAIN}${GENRES_URL}/${genre._id}`;

	try {
		const response = await api.patch<GenreType>(url, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function deleteGenre(genre: GenreType): Promise<{ success: boolean }> {
	const url = `${API_DOMAIN}${GENRES_URL}/${genre._id}`;

	try {
		const response = await api.delete(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return { success: true };
	} catch (error) {
		console.error(error);
		return { success: false };
	}
}

export async function askUpdateGenre(genre: GenreType): Promise<{ items: number; success: boolean }> {
	const url = `${API_DOMAIN}${GENRES_URL}/ask-quantity/${genre._id}`;

	try {
		const response = await api.get<{ items: number; success: boolean }>(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return { items: 0, success: false };
	}
}
