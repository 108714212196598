import { ComponentsSearchParams } from 'lib/models/components';
import ComponentsTable from './ComponentTable';

type Props = {
	components: ComponentType[];
	edit: (component: ComponentType) => void;
	onDelete: (component: ComponentType) => void;
	refetch: (params: ComponentsSearchParams) => void;
	filters: ComponentsSearchParams;
	handleFilters: <K extends keyof ComponentsSearchParams>(key: K, value: ComponentsSearchParams[K]) => void;
};

function ComponentList(props: Props) {
	return <ComponentsTable {...props} />;
}

export default ComponentList;
