const levelMaps = {
	levelMap_plural: 'Niveles',
	add_levelMap: 'Agregar nivel',
	edit_levelMap: 'Editar nivel',
	edit_levelMap_description: 'Se actualizará el nivel asignado de {{quantity}} libros.',
	delete_levelMap: 'Borrar el nivel seleccionado?',
	delete_levelMap_description: 'El nivel sera eliminado de {{quantity}} items',
	existent_items_on_delete_title: 'Existen elementos asignados con este nivel',
	existent_items_on_delete_description: 'Modifica el nivel de los items antes de eliminar el nivel.',
} as const;

export default levelMaps;
