const formats = {
	format_plural: 'Formato',
	add_format: 'Agregar Formato',
	edit_format: 'Editar Formato',
	edit_format_description: 'Se actualizará el Formato asignado de {{quantity}} libros.',
	delete_format: 'Borrar el Formato seleccionado?',
	delete_format_description: 'El Formato sera eliminado de {{quantity}} items',
	existent_items_on_delete_title: 'Existen elementos asignados con este Formato',
	existent_items_on_delete_description: 'Modifica el Formato de los items antes de eliminar el Formato.',
} as const;

export default formats;
