import { API_DOMAIN, SERIES_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function fetchSeries(search?: SeriesSearchParameters): Promise<{ data: SerieType[] | []; count: number }> {
	try {
		const response = await api.get<{ data: SerieType[]; count: number }>(`${API_DOMAIN}${SERIES_URL}`, {
			params: search,
		});

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return { data: [], count: 0 };
	}
}

export async function fetchSeriesForFilter(): Promise<SerieType[]> {
	try {
		const response = await api.get<SerieType[]>(`${API_DOMAIN}${SERIES_URL}/filter`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function createSerie(serie: SerieType): Promise<SerieType | null> {
	try {
		const data = { ...serie, name: serie.name.trim() };

		const response = await api.post<SerieType>(`${API_DOMAIN}${SERIES_URL}`, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateSerie(serie: SerieType): Promise<SerieType | null> {
	if (!serie._id) {
		delete serie._id;
	}
	const data = { ...serie, name: serie.name.trim() };

	const url = `${API_DOMAIN}${SERIES_URL}/${serie._id}`;

	try {
		const response = await api.patch<SerieType>(url, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function deleteSerie(serie: SerieType): Promise<{ success: boolean }> {
	const url = `${API_DOMAIN}${SERIES_URL}/${serie._id}`;

	try {
		const response = await api.delete(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return { success: true };
	} catch (error) {
		console.error(error);
		return { success: false };
	}
}

export async function askUpdateSerie(serie: SerieType): Promise<{ items: number; success: boolean }> {
	const url = `${API_DOMAIN}${SERIES_URL}/ask-quantity/${serie._id}`;

	try {
		const response = await api.get<{ items: number; success: boolean }>(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return { items: 0, success: false };
	}
}
