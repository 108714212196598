import { Button, FormGroup, makeStyles, Modal, TextField, Typography } from '@material-ui/core';
import PaperContainer from 'components/containers/PaperContainer';
import { useTranslation } from 'react-i18next';
type Props = {
	title: string;
	description: string | undefined;
	onSubmit: () => void;
	onCancel: () => void;
	onUpdate: (value: string, key?: string) => void;
	item: SubjectType | CurriculumType | CertificateType | SerieType;
};

function ModalForm({ title, description, onSubmit, onCancel, onUpdate, item }: Props) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Modal open={true} className={classes.modal}>
			<PaperContainer>
				<FormGroup>
					<Typography variant="h6">{title}</Typography>
					<TextField
						id="title"
						fullWidth={true}
						value={item.name}
						variant="outlined"
						className={classes.input}
						onChange={(ev) => onUpdate(ev.currentTarget.value, 'name')}
						type="text"
					/>

					{!!description && <Typography variant="subtitle1">{description}</Typography>}
					<Button
						variant="contained"
						color="primary"
						disabled={!item.name}
						onClick={() => onSubmit()}
						className={classes.button}
					>
						{t('common:save')}
					</Button>
					<Button variant="contained" color="secondary" onClick={() => onCancel()} className={classes.button}>
						{t('common:cancel')}
					</Button>
				</FormGroup>
			</PaperContainer>
		</Modal>
	);
}

export default ModalForm;

const useStyles = makeStyles((theme) => ({
	modal: {
		width: '100%',
		maxWidth: '500px',
		margin: 'auto',
		marginTop: theme.spacing(2),
	},
	button: {
		marginTop: theme.spacing(2),
	},
	input: {
		marginTop: theme.spacing(2),
	},
}));
