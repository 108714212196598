import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { MenuItem } from 'types/global';

type UsersProps = {
	selected: MenuItem;
	onSelect(event?: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: MenuItem): void;
};

export default function ItemTypes({ selected, onSelect }: UsersProps) {
	const { t } = useTranslation();

	return (
		<ListItem
			button
			component={Link}
			to="/item-types"
			selected={selected === MenuItem.ItemTypes}
			onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) => onSelect(event, MenuItem.ItemTypes)}
		>
			<ListItemIcon>
				<CheckCircle />
			</ListItemIcon>
			<ListItemText primary={t('itemTypes:itemTypes')} />
		</ListItem>
	);
}
