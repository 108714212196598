import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Alert, { AlertsTypes } from 'components/Alert';
import {
	Button,
	createStyles,
	FormGroup,
	LinearProgress,
	makeStyles,
	TextField,
	Theme,
	Switch,
	FormControlLabel,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';

import { updateItem } from 'lib/models/items';

import useItem from './useItem';
import SelectInput, { FilterKey } from './SelectInput';
import { levels } from 'teachers-types';

import ShowroomInput from './ShowroomInput';
import LabelInput from './LabelInput';

import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import { fakeItemsRequest } from 'lib/models/items';
import InputContainer from 'components/containers/InputContainer';
//import ComponentInput from './ComponentInput';

function ItemEdition() {
	const { t } = useTranslation();

	const history = useHistory();
	const classes = useStyles();

	const { id } = useParams<{ id: string }>();

	const newItem = !id || id === 'new';

	const title = newItem ? t('items:createItem') : t('items:editItem');

	const { item, loading, onUpdate, setLoading, setItem, updated } = useItem(id);

	const [error, setError] = useState<string>('');
	const [success, setSuccess] = useState<boolean>(false);

	const save = useCallback(
		async (data: Item) => {
			try {
				setLoading(true);
				const response = await updateItem(data);
				const updatedItem = Array.isArray(response) ? response[0] : response;
				setItem(updatedItem);
				setSuccess(true);
				history.push(`/items/${updatedItem._id!}`);
			} catch (error) {
				setError(error && (error as any).message ? (error as any).message : error);
			}
			setLoading(false);
		},
		[history, setItem, setLoading]
	);

	const fakeItems = async () => {
		try {
			setLoading(true);
			await fakeItemsRequest();
			history.push('/items');
		} catch (error) {
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
	};

	const itemCreated = !loading && success && newItem;

	const setFilter = useCallback(
		(ev: React.ChangeEvent<{ value: unknown }>, key: FilterKey) => {
			setItem((current) => {
				const { value } = ev.target;
				let extras = {};
				if (key === 'academicLevel') {
					const key = value as keyof typeof levels;
					const grades = levels[key];
					if (Array.isArray(grades) && grades[0]) {
						extras = { grade: grades[0] };
					}
				}

				return { ...current, [key]: ev.target.value, ...extras };
			});
		},
		[setItem]
	);

	/*const onUpdateComponent = (value: string) => {
		setItem({ ...item, component: value });
	};*/

	const onItemUpdated = useCallback(
		(updatedItem) => {
			setItem(updatedItem);
		},
		[setItem]
	);

	return (
		<ScreenContainer title={title}>
			<PaperContainer>
				{loading && <LinearProgress className={classes.input} />}

				<FormGroup className={classes.form}>
					<TextField
						id="kelCode"
						label={t('items:kelCode')}
						fullWidth={true}
						value={item.kelCode}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('kelCode')}
						type="number"
					/>

					<TextField
						id="isbn"
						label={t('items:isbn')}
						fullWidth={true}
						value={item.isbn}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('isbn')}
						error={item.isbn === ''}
						helperText={item.isbn === '' ? t('items:isbnRequired') : ''}
					/>
					<SelectInput filterKey="type" value={item.type} setFilter={setFilter} />

					<InputContainer
						title={`${t('items:isFeatured')} | ${t('items:isRecommended')}`}
						fullWidth={true}
						className={classes.input}
					>
						<FormControlLabel
							id="isFeatured"
							control={<Switch checked={item.isFeatured} onChange={onUpdate('isFeatured')} />}
							label={t('items:isFeatured')}
						/>
						<FormControlLabel
							id="is"
							control={<Switch checked={item.isRecommended} onChange={onUpdate('isRecommended')} />}
							label={t('items:isRecommended')}
						/>
					</InputContainer>

					<TextField
						id="title"
						label={t('items:title')}
						fullWidth={true}
						value={item.title}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('title')}
					/>

					<TextField
						id="description"
						label={t('items:description')}
						fullWidth={true}
						value={item.description}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('description')}
					/>

					<TextField
						id="thumbnail"
						label={t('items:thumbnail')}
						fullWidth={true}
						value={item.thumbnail}
						variant="outlined"
						className={`${classes.input} ${!!item.thumbnail ? classes.thumbnailText : ''}`}
						onChange={onUpdate('thumbnail')}
						type="url"
					/>

					{!!item.thumbnail && <img src={item.thumbnail} className={classes.thumbnail} alt="thumbnail" />}

					<TextField
						id="author"
						label={t('items:author')}
						fullWidth={true}
						value={item.author}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('author')}
					/>

					<TextField
						id="editorial"
						label={t('items:editorial')}
						fullWidth={true}
						value={item.editorial}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('editorial')}
					/>

					<TextField
						id="amount"
						label={t('items:stock')}
						fullWidth={false}
						value={item.stock ?? 0}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('stock')}
						type="number"
					/>

					<TextField
						id="price"
						label={t('items:price')}
						fullWidth={false}
						value={item.price}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('price')}
						type="number"
					/>

					<SelectInput filterKey="approach" value={item.approach} setFilter={setFilter} />

					<SelectInput filterKey="certificate" value={item.certificate} setFilter={setFilter} />
					<SelectInput filterKey="format" value={item.format} setFilter={setFilter} />

					<SelectInput filterKey="cycle" value={item.cycle} setFilter={setFilter} />

					{/*<ComponentInput value={item.component} itemType={item.type} onUpdateComponent={onUpdateComponent} />*/}

					<SelectInput filterKey="component" value={item.component} setFilter={setFilter} />

					<SelectInput filterKey="curriculum" value={item.curriculum} setFilter={setFilter} />

					<SelectInput filterKey="language" value={item.language} setFilter={setFilter} />

					<SelectInput filterKey="serie" value={item.serie} setFilter={setFilter} />

					<SelectInput filterKey="genre" value={item.genre} setFilter={setFilter} />

					<SelectInput filterKey="subject" value={item.subject} setFilter={setFilter} />

					<SelectInput filterKey="workload" value={item.workload} setFilter={setFilter} />

					<SelectInput filterKey="academicLevel" value={item.academicLevel} setFilter={setFilter} />

					<SelectInput filterKey="cefrLevel" value={item.cefrLevel} setFilter={setFilter} />

					<SelectInput filterKey="grade" value={item.grade} parentValue={item.academicLevel} setFilter={setFilter} />

					<TextField
						id="edition"
						label={t('items:edition')}
						fullWidth={false}
						value={item.edition}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('edition')}
						type="number"
					/>

					<TextField
						id="relevance"
						label={t('items:relevance')}
						fullWidth={false}
						value={item.relevance ? item.relevance : 0}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('relevance')}
						inputProps={{ min: 0, max: 10 }}
						type="number"
					/>

					<ShowroomInput onUpdate={onItemUpdated} disabled={loading || newItem} item={item} />

					<LabelInput onUpdate={onItemUpdated} disabled={loading || newItem} item={item} />
				</FormGroup>

				<Button
					variant="contained"
					color="primary"
					size="large"
					className={classes.button}
					startIcon={<SaveIcon />}
					onClick={() => save(item)}
					fullWidth={true}
					disabled={!updated}
				>
					{t('common:save')}
				</Button>

				{process.env.NODE_ENV === 'development' && (
					<Button
						variant="contained"
						color="secondary"
						size="large"
						className={classes.button}
						startIcon={<FlightTakeoffIcon />}
						onClick={fakeItems}
						fullWidth={true}
					>
						{t('common:fake')}
					</Button>
				)}

				{!loading && success && (
					<Alert
						message={t('common:success')}
						show={success}
						type={AlertsTypes.success}
						onClose={() => !itemCreated && setSuccess(false)}
					/>
				)}
				{!loading && error && (
					<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />
				)}
			</PaperContainer>
		</ScreenContainer>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			display: 'flex',
			flexDirection: 'row',
			gap: theme.spacing(2),
		},
		input: {
			marginBottom: theme.spacing(2),
			maxWidth: '400px',
		},
		large: {
			width: theme.spacing(10),
			height: theme.spacing(10),
			margin: theme.spacing(4),
		},
		button: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(1),
		},
		text: {
			marginTop: theme.spacing(2),
		},
		thumbnailText: {
			maxWidth: '300px',
		},
		thumbnail: {
			borderWidth: '2px',
			borderColor: theme.palette.divider,
			borderRadius: theme.spacing(1),
			borderStyle: 'solid',
			padding: '4px',
			width: '100px',
			height: '55px',
			objectFit: 'contain',
		},
	})
);

export default ItemEdition;
