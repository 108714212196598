import { useCallback, useState, useEffect, useMemo } from 'react';

import { fetchItem } from 'lib/models/items';
import {
	academicLevels,
	approaches,
	cefrLevels,
	certificates,
	curriculums,
	itemTypes,
	languages,
	levels,
	subjects,
	workloads,
	genres,
	formats,
	cycles,
} from 'teachers-types';
import { getStringFromData } from 'lib/helpers';

const defaultItem: Item = {
	_id: '',
	academicLevel: academicLevels[0],
	approach: approaches[0],
	author: '',
	cefrLevel: cefrLevels[0],
	certificate: certificates[0],
	component: '',
	curriculum: curriculums[0],
	description: '',
	edition: 1,
	editorial: '',
	genre: genres[0],
	format: formats[0],
	cycle: cycles[0],
	grade: levels.Inicial[0],
	isbn: '',
	isFeatured: false,
	isRecommended: false,
	kelCode: 1,
	language: languages[0],
	price: 0,
	serie: '',
	showroom: [],
	subject: subjects[0],
	thumbnail: '',
	title: '',
	workload: workloads[0],
	type: itemTypes[0],
	stock: 0,
};

function useItem(id?: string) {
	const [loading, setLoading] = useState<boolean>(true);
	const [item, setItem] = useState<Item>(defaultItem);
	const [originalItem, setOriginalItem] = useState<Item>(defaultItem);

	const onUpdate = useCallback((key: keyof Item) => {
		return (event: any) => {
			const value = key === 'isFeatured' || key === 'isRecommended' ? event.target.checked : event.target.value;
			setItem((current) => ({ ...current, [key]: value }));
		};
	}, []);

	const refetch = useCallback(async (): Promise<void> => {
		if (id && id !== 'new') {
			setLoading(true);
			const response = await fetchItem(id);
			if (response) {
				setItem(response);
				setOriginalItem(response);
			}
		}
		setLoading(false);
	}, [id]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	const updated = useMemo(() => {
		return getStringFromData(item) !== getStringFromData(originalItem);
	}, [item, originalItem]);

	return { loading, item, refetch, onUpdate, setLoading, setItem, updated };
}

type UseItem = ReturnType<typeof useItem>;

export type FetchItemMethod = UseItem['refetch'];

export default useItem;
