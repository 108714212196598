import { Box, Button, createStyles, ListItemText, makeStyles, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import FullLayout from 'components/layouts/FullLayout';
import ResponsiveTable from 'components/ResponsiveTable';
import SearchToolbar from 'components/SearchToolbar';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { rowsPerPageOptions } from 'config/constants';

type Props = {
	loading?: boolean;
	onSelect?(list: List): any;
	setOpenModal?(): void;
	series: SerieType[];
	edit: (series: SerieType) => void;
	onDelete: (series: SerieType) => void;
	count: number;
	refetch: (search: SeriesSearchParameters) => void;
};

function SeriesTable({ series, count, refetch, onDelete, edit }: Props) {
	const { t } = useTranslation();
	const classes = useStyles();

	const [name, setText] = useState('');

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);

	const onSearch = useCallback(() => {
		const itemSearchParameters: SeriesSearchParameters = {
			filters: { name },
			limit: rowsPerPage,
			offset: rowsPerPage * page,
		};
		refetch(itemSearchParameters);
	}, [name, page, refetch, rowsPerPage]);

	useEffect(() => {
		onSearch();
	}, [onSearch]);

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<FullLayout>
			<Box>
				<SearchToolbar placeholder={t('items:search')} onSearch={(value) => setText(value)} live={false} />
			</Box>

			<ResponsiveTable
				loading={false}
				elements={series}
				list={{
					primaryKey: '_id',
					renderListItemText: (item) => {
						return <ListItemText key={item.name} primary={`${item.name}`} />;
					},
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell>{t('series:serie')}</TableCell>
								<TableCell align="right">{t('common:actions')}</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (item) => {
						return (
							<TableRow key={item._id || item.name} className={classes.row}>
								<TableCell>{item.name}</TableCell>
								<TableCell align="right" className={classes.actions}>
									<Button
										className={classes.button}
										variant="contained"
										fullWidth={true}
										color="primary"
										size="medium"
										startIcon={<Edit />}
										disabled={item.disabled}
										onClick={() => edit(item)}
									>
										{t('common:edit')}
									</Button>
									<Button
										className={classes.button}
										variant="contained"
										fullWidth={true}
										color="secondary"
										size="medium"
										startIcon={<Delete />}
										disabled={item.disabled}
										onClick={() => onDelete(item)}
									>
										{t('common:delete')}
									</Button>
								</TableCell>
							</TableRow>
						);
					},
					pagination: {
						count,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: handleChangePage,
						onRowsPerPageChange: handleChangeRowsPerPage,
					},
				}}
			/>
		</FullLayout>
	);
}

export default SeriesTable;

const useStyles = makeStyles((theme) => {
	return createStyles({
		card: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: theme.spacing(2),
			padding: theme.spacing(1),
		},
		actions: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'end',
			gap: theme.spacing(1),
		},
		button: {
			width: '150px',
		},
		row: {},
	});
});
