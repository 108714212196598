import { SiteConfiguration, fetchSiteConfiguration, updateSiteConfiguration } from 'lib/models/siteConfiguration';
import { useCallback, useEffect, useState } from 'react';

function useSiteConfiguration() {
	const [config, setConfig] = useState<SiteConfiguration>({ instructivePdfUrl: '' });
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function init() {
			try {
				const res = await fetchSiteConfiguration();
				setConfig(res);
			} catch (error) {
				console.error(error);
				setConfig({ instructivePdfUrl: '' });
			}
			setLoading(false);
		}

		init();
	}, []);

	const updateInstructivePdfUrl = useCallback((url: string) => {
		setConfig((current) => {
			return { ...current, instructivePdfUrl: url };
		});
	}, []);

	const addStockAmounts = useCallback((text: string, minAmount: number, backgroundColor: string, textColor: string) => {
		setConfig((current) => {
			return {
				...current,
				stockAmounts: [...(current.stockAmounts || []), { text, minAmount, backgroundColor, textColor }],
			};
		});
	}, []);

	const updateStockAmounts = useCallback(
		(text: string, minAmount: number, backgroundColor: string, textColor: string, index: number) => {
			setConfig((current) => {
				// On edition, always exists!
				if (typeof current.stockAmounts?.[index] === 'undefined') {
					throw new Error(`index not found: ${index}`);
				}
				return {
					...current,
					stockAmounts: current.stockAmounts.map((el, currIndex) => {
						return currIndex === index ? { text, minAmount, backgroundColor, textColor } : el;
					}),
				};
			});
		},
		[]
	);

	const removeStockAmount = useCallback((index: number) => {
		setConfig((current) => {
			return {
				...current,
				stockAmounts: (current.stockAmounts || []).filter((_, currIndex) => currIndex !== index),
			};
		});
	}, []);

	const save = useCallback(async () => {
		try {
			setLoading(true);
			const saved = await updateSiteConfiguration(config);
			setConfig(saved);
		} catch (error) {
			console.error(error);
			setConfig({ instructivePdfUrl: '' });
		}
		setLoading(false);
	}, [config]);

	return {
		config,
		loading,
		updateInstructivePdfUrl,
		addStockAmounts,
		updateStockAmounts,
		removeStockAmount,
		save,
	};
}

export default useSiteConfiguration;
