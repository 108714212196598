import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';

import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';

import useUser from 'hooks/useUser';

import UserEditionForm from 'components/UserEditionForm';

function TeacherEdition() {
	const { t } = useTranslation();

	const history = useHistory();

	const { id } = useParams<{ id: string }>();
	const newUser = !!id && id === 'new';

	const userData = useUser();

	const loading = userData.loading || newUser;

	const title = loading ? t('common:loading') : t('general:userTitle', { name: userData.user.first_name });

	const [success, setSuccess] = useState<boolean>(false);

	const onEdition = async (_user?: User | null) => {
		setSuccess(true);
		history.push('/teachers');
	};

	const userCreated = success && newUser;

	useEffect(() => {
		if (newUser) {
			history.push('/teachers');
		}
	}, [newUser, history]);

	return (
		<ScreenContainer title={title}>
			<PaperContainer>
				{loading && <LinearProgress />}

				{!userCreated && !loading && (
					<UserEditionForm
						editingSelf={false}
						isModal={false}
						onEdition={onEdition}
						schoolHubspotId={userData.user.schoolHubspotId}
						useDisableEdit={false}
						withDelete={true}
						withPasswordReset={false}
						withStatus={false}
						id={id}
					/>
				)}
			</PaperContainer>
		</ScreenContainer>
	);
}

export default TeacherEdition;
