import SeriesTable from './SeriesTable';

type Props = {
	series: SerieType[];
	edit: (serie: SerieType) => void;
	onDelete: (serie: SerieType) => void;
	count: number;
	refetch: (search: SeriesSearchParameters) => void;
};

function SeriesList(props: Props) {
	return <SeriesTable {...props} />;
}

export default SeriesList;
