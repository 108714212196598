import { createStyles, makeStyles, TableCell } from '@material-ui/core';
import moment from 'moment';
import Actions from './Actions';

type Props = {
	setShowing: React.Dispatch<React.SetStateAction<boolean>>;
	onSelect?: (list: List) => any;
	list: List;
	onRefetch: () => any;
};

function ListCells({ list, setShowing, onSelect, onRefetch }: Props) {
	const classes = useStyles();
	return (
		<>
			<TableCell className={classes.actions}>{list.name}</TableCell>
			<TableCell className={classes.actions}>{list.author?.email}</TableCell>
			{/* <TableCell>{moment(list.createdAt).format('DD/MM/yyyy HH:mm')}</TableCell> */}
			<TableCell className={classes.actions}>{moment(list.updatedAt).format('DD/MM/yyyy HH:mm')}</TableCell>
			<TableCell className={classes.actions}>
				<Actions list={list} setShowing={setShowing} onSelect={onSelect} onRefetch={onRefetch} />
			</TableCell>
		</>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		actions: {},
	})
);

export default ListCells;
