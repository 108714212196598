import { Button, Checkbox, FormControlLabel, FormGroup, makeStyles } from '@material-ui/core';
import Alert, { AlertsTypes } from 'components/Alert';
import InputContainer from 'components/containers/InputContainer';
import ScreenContainer from 'components/containers/ScreenContainer';
import useItemTypes from 'hooks/useItemTypes';
import { updateItemType } from 'lib/models/itemTypes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { componentFeatures } from 'types/constants';

function ItemTypes() {
	const { t } = useTranslation();
	const classes = useStyles();
	const { data, loading, setLoading, refetch } = useItemTypes();
	const [itemTypes, setItemTypes] = useState<ItemType[]>(data);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		if (data) {
			setItemTypes(data);
		}
	}, [data]);

	const onUpdate = async (itemType: 'Text' | 'Literature', value: Feature) => {
		setItemTypes((prevItemTypes) =>
			prevItemTypes.map((item) =>
				item.name === itemType
					? {
							...item,
							features: item.features.some((f) => f.displayName === value.displayName)
								? item.features.filter((f) => f.displayName !== value.displayName)
								: [...item.features, value],
					  }
					: item
			)
		);
	};

	const save = async () => {
		setLoading(true);
		try {
			await updateItemType(itemTypes);
			setSuccess(true);
			refetch();
		} catch (error) {
			console.error(error);
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
		resetAlert();
	};
	const resetAlert = () => {
		setTimeout(() => {
			setSuccess(false);
			setError('');
		}, 3000);
	};

	return (
		<ScreenContainer title={t('itemTypes:itemTypes')}>
			<FormGroup className={classes.form}>
				<InputContainer title={t('itemTypes:text')}>
					{componentFeatures.map((f) => (
						<FormControlLabel
							onChange={() => {
								onUpdate('Text', f);
							}}
							label={f.displayName}
							control={
								<Checkbox
									checked={hasFeature(
										f,
										itemTypes.find((e) => e.name === 'Text')
									)}
								/>
							}
						/>
					))}
				</InputContainer>
				<InputContainer title={t('itemTypes:literature')}>
					{componentFeatures.map((f) => (
						<FormControlLabel
							onChange={() => {
								onUpdate('Literature', f);
							}}
							label={f.displayName}
							control={
								<Checkbox
									checked={hasFeature(
										f,
										itemTypes.find((e) => e.name === 'Literature')
									)}
								/>
							}
						/>
					))}
				</InputContainer>
				<Button variant="contained" color="primary" className={classes.button} onClick={save}>
					{t('common:save')}
				</Button>
				<Button variant="contained" color="secondary" className={classes.button} onClick={refetch}>
					{t('common:cancel')}
				</Button>
			</FormGroup>
			{!loading && success && (
				<Alert
					message={t('common:success')}
					show={success}
					type={AlertsTypes.success}
					onClose={() => setSuccess(false)}
				/>
			)}
			{!loading && error && (
				<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />
			)}
		</ScreenContainer>
	);
}

export default ItemTypes;

const hasFeature = (value: Feature, item?: ItemType) => {
	if (item && item.features && item.features.length) {
		return item.features.some((f) => f.displayName === value.displayName);
	}
	return false;
};

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%',
		marginTop: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		paddingBottom: theme.spacing(3),
	},
	button: {
		marginTop: theme.spacing(2),
		width: '70%',
	},
	input: {
		marginTop: theme.spacing(3),
	},
}));
