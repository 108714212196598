import { API_DOMAIN, SUBJECTS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function fetchSubjects(): Promise<SubjectType[]> {
	try {
		const response = await api.get<SubjectType[]>(`${API_DOMAIN}${SUBJECTS_URL}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}
export async function createSubject(subject: SubjectType): Promise<SubjectType | null> {
	try {
		const data = { ...subject, name: subject.name.trim() };

		const response = await api.post<SubjectType>(`${API_DOMAIN}${SUBJECTS_URL}`, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateSubject(subject: SubjectType): Promise<SubjectType | null> {
	if (!subject._id) {
		delete subject._id;
	}
	const data = { ...subject, name: subject.name.trim() };

	const url = `${API_DOMAIN}${SUBJECTS_URL}/${subject._id}`;

	try {
		const response = await api.patch<SubjectType>(url, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function deleteSubject(subject: SubjectType): Promise<{ success: boolean }> {
	const url = `${API_DOMAIN}${SUBJECTS_URL}/${subject._id}`;

	try {
		const response = await api.delete(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return { success: true };
	} catch (error) {
		console.error(error);
		return { success: false };
	}
}

export async function askUpdateSubject(subject: SubjectType): Promise<{ items: number; success: boolean }> {
	const url = `${API_DOMAIN}${SUBJECTS_URL}/ask-quantity/${subject._id}`;

	try {
		const response = await api.get<{ items: number; success: boolean }>(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return { items: 0, success: false };
	}
}
