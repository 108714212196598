const items = {
	isFeatured: 'Destacados',
	isbnRequired: 'Este campo es obligatorio',
	isRecommended: 'Recomendados',
	subject: 'Materia',
	curriculum: 'Curriculum',
	approach: 'Abordaje',
	workload: 'Carga horaria',
	certificate: 'Examen',
	serie: 'Serie',
	language: 'Idioma',
	level: 'Nivel',
	component: 'Component',
	search: 'Buscar libros',
	deleteShowroomItemTitle: '¿Quitar item?',
	deleteShowroomItemDescription: '¿Está seguro que desea quitar el elemento seleccionado del Showroom?',
	deleteLabelTitle: '¿Quitar etiqueta?',
	deleteLabelDescription: '¿Está seguro que desea quitar la etiqueta?',
	warningUploader:
		'ADVERTENCIA: si el archivo que estás subiendo contiene elementos que ya están presentes en la base de datos, los sobrescribirá con los nuevos valores y se perderán todos los cambios que hayas realizado en los mismos.',
	deleteTitle: 'Eliminación de libros',
	deleteDescription:
		'¿Está seguro de que desea eliminar los libros seleccionados? Esta acción también los eliminará de las listas creadas por los docentes.',
	cefrLevel: 'Nivel CEFR',
	syncSuccess: 'Se sincronizó correctamente la base de datos',
	syncStart: 'Comenzar sincronización',
	sync: 'Sincronizar',
};

export default items;
