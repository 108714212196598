import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	makeStyles,
	Modal,
	TextField,
	Typography,
} from '@material-ui/core';
import PaperContainer from 'components/containers/PaperContainer';
import { useTranslation } from 'react-i18next';
type Props = {
	title: string;
	description?: string;
	onSubmit: () => void;
	onCancel: () => void;
	onUpdate: (value: string, key: keyof ComponentType) => void;
	item: ComponentType;
};

function ComponentForm({ title, description, onSubmit, onCancel, onUpdate, item }: Props) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Modal open={true} className={classes.modal}>
			<PaperContainer>
				<FormGroup>
					<Typography variant="h6">{title}</Typography>
					<TextField
						id="title"
						label="Title"
						fullWidth={true}
						value={item.name}
						variant="outlined"
						className={classes.input}
						onChange={(ev) => onUpdate(ev.currentTarget.value, 'name')}
						type="text"
					/>
					<Box className={classes.input}>
						<Typography>{t('components:select_type')}</Typography>
						<FormControlLabel
							onChange={() => onUpdate('Text', 'types')}
							label={t('common:Text')}
							control={<Checkbox checked={hasValue(item, 'types', 'Text')} />}
						/>
						<FormControlLabel
							onChange={() => onUpdate('Literature', 'types')}
							label={t('common:Literature')}
							control={<Checkbox checked={hasValue(item, 'types', 'Literature')} />}
						/>
					</Box>

					{!!description && <Typography variant="subtitle1">{description}</Typography>}
					<Button
						variant="contained"
						color="primary"
						disabled={!item.name}
						onClick={() => onSubmit()}
						className={classes.button}
					>
						{t('common:save')}
					</Button>
					<Button variant="contained" color="secondary" onClick={() => onCancel()} className={classes.button}>
						{t('common:cancel')}
					</Button>
				</FormGroup>
			</PaperContainer>
		</Modal>
	);
}

export default ComponentForm;

const useStyles = makeStyles((theme) => ({
	modal: {
		width: '100%',
		maxWidth: '500px',
		margin: 'auto',
		marginTop: theme.spacing(2),
	},
	button: {
		marginTop: theme.spacing(2),
	},
	input: {
		marginTop: theme.spacing(3),
	},
}));

const hasValue = (item: ComponentType, key: 'types', value: string): boolean | undefined => {
	return item[key]?.includes(value);
};
