import { fetchItemTypes } from 'lib/models/itemTypes';
import { useCallback, useEffect, useState } from 'react';

function useItemTypes() {
	const [loading, setLoading] = useState<boolean>(true);
	const [data, setData] = useState<ItemType[]>([]);

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchItemTypes();
		setData(response);
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, data, refetch, setLoading };
}

export default useItemTypes;
