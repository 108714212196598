import { Box, Button, Checkbox, createStyles, FormControlLabel, makeStyles, Typography } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
// import InputContainer from 'components/containers/InputContainer';
import PaperContainer from 'components/containers/PaperContainer';
import FullLayout from 'components/layouts/FullLayout';
import SearchToolbar from 'components/SearchToolbar';
import { ComponentsSearchParams } from 'lib/models/components';
import { useTranslation } from 'react-i18next';
import { ItemTypes } from 'teachers-types';

type Props = {
	loading?: boolean;
	onSelect?(list: List): any;
	setOpenModal?(): void;
	components: ComponentType[];
	edit: (component: ComponentType) => void;
	onDelete: (component: ComponentType) => void;
	filters: ComponentsSearchParams;
	handleFilters: <K extends keyof ComponentsSearchParams>(key: K, value: ComponentsSearchParams[K]) => void;
};

function ComponentsTable({ components, edit, onDelete, filters, handleFilters }: Props) {
	const { t } = useTranslation();
	const classes = useStyles();

	const handleTypes = (type: ItemTypes) =>
		filters.types.includes(type) ? [...filters.types.filter((t) => t !== type)] : [...filters.types, type];

	return (
		<FullLayout className={classes.layout}>
			<Box className={classes.toolbar}>
				<SearchToolbar
					placeholder={t('components:search')}
					onSearch={(value) => handleFilters('text', value)}
					live={false}
				></SearchToolbar>

				{/* <InputContainer className={classes.exact} title={t('itemTypes:itemType')}> */}
				<FormControlLabel
					control={
						<Checkbox
							checked={filters.types.includes('Text')}
							onChange={() => handleFilters('types', handleTypes('Text'))}
							name="exact"
							color="primary"
						/>
					}
					label={t('itemTypes:text')}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={filters.types.includes('Literature')}
							onChange={() => handleFilters('types', handleTypes('Literature'))}
							name="exact"
							color="primary"
						/>
					}
					label={t('itemTypes:literature')}
				/>
				{/* </InputContainer> */}
			</Box>
			{components.map((c) => {
				return (
					<PaperContainer key={c._id} className={classes.card}>
						<Box sx={{ maxWidth: '50%' }}>
							<Typography>
								<strong>{c.name}</strong>
							</Typography>
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'flex-end',
								paddingLeft: 2,
							}}
							className={classes.actions}
						>
							<Button
								variant="contained"
								fullWidth={true}
								color="primary"
								size="medium"
								startIcon={<Edit />}
								onClick={() => {
									edit(c);
								}}
								disabled={c.required}
							>
								{t('common:edit')}
							</Button>
							<Button
								variant="contained"
								fullWidth={true}
								color="secondary"
								size="medium"
								startIcon={<Delete />}
								onClick={() => {
									onDelete(c);
								}}
								disabled={c.required}
							>
								{t('common:delete')}
							</Button>
						</Box>
					</PaperContainer>
				);
			})}
		</FullLayout>
	);
}

export default ComponentsTable;

const useStyles = makeStyles((theme) => {
	return createStyles({
		cardTitle: {
			width: '100%',
			wordWrap: 'break-word',
			// "word-wrap": "break-word";
			// overflow-wrap: break-word;
			// white-space: normal;
		},
		layout: { padding: '0.5rem 0.5rem' },
		toolbar: {
			display: 'flex',
			flexDirection: 'row',
		},
		exact: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		card: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: theme.spacing(2),
			padding: theme.spacing(1),
		},
		edit: {},
		delete: {},
		actions: {
			gap: theme.spacing(1),
		},
	});
});
