import { API_DOMAIN, SITE_CONFIGURATION_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export type SiteConfiguration = {
	instructivePdfUrl: string;
	stockAmounts?: {
		text: string;
		minAmount: number;
		backgroundColor: string;
		textColor: string;
	}[];
};

export async function fetchSiteConfiguration(): Promise<SiteConfiguration> {
	try {
		const response = await api.get<SiteConfiguration>(`${API_DOMAIN}${SITE_CONFIGURATION_URL}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return {
			instructivePdfUrl: '',
		};
	}
}

export async function updateSiteConfiguration(config: SiteConfiguration): Promise<SiteConfiguration> {
	const url = `${API_DOMAIN}${SITE_CONFIGURATION_URL}`;

	const response = await api.patch<SiteConfiguration>(url, { config });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || config;
}
