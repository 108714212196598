import { API_DOMAIN, LEVELMAP_URL } from 'config/constants';
import { api, importDataRequest } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function fetchLevelMap(): Promise<LevelMapType[]> {
	try {
		const response = await api.get<LevelMapType[]>(`${API_DOMAIN}${LEVELMAP_URL}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function createLevelMap(levelMap: LevelMapType): Promise<LevelMapType | null> {
	try {
		const data = { ...levelMap, code: levelMap.code.trim() };
		const response = await api.post<LevelMapType>(`${API_DOMAIN}${LEVELMAP_URL}`, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateLevelMap(levelMap: LevelMapType): Promise<LevelMapType | null> {
	if (!levelMap._id) {
		delete levelMap._id;
	}
	const data = { ...levelMap, name: levelMap.code.trim() };

	const url = `${API_DOMAIN}${LEVELMAP_URL}/${levelMap._id}`;

	try {
		const response = await api.patch<LevelMapType>(url, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function deleteLevelMap(levelMap: LevelMapType): Promise<{ success: boolean }> {
	const url = `${API_DOMAIN}${LEVELMAP_URL}/${levelMap._id}`;

	try {
		const response = await api.delete(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return { success: true };
	} catch (error) {
		console.error(error);
		return { success: false };
	}
}

export async function askUpdateLevelMap(levelMap: LevelMapType): Promise<{ items: number; success: boolean }> {
	const url = `${API_DOMAIN}${LEVELMAP_URL}/ask-quantity/${levelMap._id}`;

	try {
		const response = await api.get<{ items: number; success: boolean }>(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return { items: 0, success: false };
	}
}

export function importData(file: File): Promise<ParseCsvFileResult<LevelMap>> {
	const url = `${API_DOMAIN}${LEVELMAP_URL}/import`;
	return importDataRequest(url, file);
}

export async function removeLevels(levels: LevelMap[]): Promise<{ success: boolean }> {
	const url = `${API_DOMAIN}${LEVELMAP_URL}/`;

	const response = await api.delete<{ success: boolean }>(url, { data: levels });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || false;
}
