import { useTranslation } from 'react-i18next';

import ScreenContainer from 'components/containers/ScreenContainer';
import { useCallback, useEffect, useState } from 'react';
import { askUpdateLevelMap, deleteLevelMap, fetchLevelMap } from 'lib/models/levelMap';
import Alert, { AlertsTypes } from 'components/Alert';
import ConfirmationDialog from 'components/ConfirmationDialog';
import LevelMapsList from 'components/LevelMapsList';
import { useHistory } from 'react-router-dom';
import { Box, DialogContentText, Fab } from '@material-ui/core';
import { importData } from 'lib/models/levelMap';
import Uploader from 'components/Uploader';
import { CloudUpload } from '@material-ui/icons';

function LevelMaps() {
	const history = useHistory();
	const { t } = useTranslation();

	const [levelMaps, setLevelMaps] = useState<LevelMapType[]>([]);

	const [levelMap, setLevelMap] = useState<LevelMapType>({ code: '', description: '', id: '' });
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [modifyQuantity, setModifyQuantity] = useState(0);
	const [deleted, setDeleted] = useState(false);
	const [importing, toggleImport] = useState<boolean>(false);
	const [error, setError] = useState('');

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchLevelMap();
		if (response) {
			setLevelMaps(response);
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	const edit = async (levelMap: LevelMapType) => {
		const { success, items } = await askUpdateLevelMap(levelMap);
		if (success) {
			setModifyQuantity(items);
			setLevelMap(levelMap);
			//setOpen(true);
		}
	};

	const onDelete = async (levelMap: LevelMapType) => {
		const { success, items } = await askUpdateLevelMap(levelMap);
		if (success) {
			setModifyQuantity(items);
			setConfirmDeleteModal(true);
			setLevelMap(levelMap);
		}
	};
	/*
	const save = async () => {
		setLoading(true);
		try {
			levelMap._id ? await updateLevelMap(levelMap) : await createLevelMap(levelMap);
			setSuccess(true);
			refetch();
			closeModal();
		} catch (error) {
			console.error(error);
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
		resetAlert();
	};

	const onUpdate = async (value: string) => {
		setLevelMap((prev) => {
			return { ...prev, name: value };
		});
	};*/

	const confirmDelete = async (confirm: boolean) => {
		if (confirm) {
			try {
				await deleteLevelMap(levelMap);
				setDeleted(true);
				setConfirmDeleteModal(false);
				refetch();
			} catch (error: any) {
				console.error(error);
				setError(error.message || error);
			}
		} else {
			setLevelMap({ code: '', description: '', id: '' });
			setConfirmDeleteModal(false);
		}
		resetAlert();
	};

	/*const closeModal = () => {
		//setOpen(false);
		setLevelMap({ code: '', description:'', id: '' });
		setModifyQuantity(0);
	};*/

	const resetAlert = () => {
		setTimeout(() => {
			setSuccess(false);
			setError('');
			setDeleted(false);
		}, 3000);
	};

	const dialogDescription =
		modifyQuantity > 0
			? t('levelMaps:existent_items_on_delete_description')
			: t('levelMaps:delete_component_description', { quantity: modifyQuantity });
	return (
		<ScreenContainer title={t('levelMaps:levelMap_plural')}>
			<Uploader
				id="levelMap-file"
				name="levelMap-file"
				accept=".csv"
				onCancel={() => toggleImport(false)}
				onEnded={(_) => toggleImport(false)}
				openDialog={importing}
				importData={importData}
				warning={t('levelMaps:warningUploader')}
				exampleFile={'items-example.csv'}
				errorKey="isbn"
				errorKeyName="isbn"
				refetch={refetch}
			/>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
					flexGrow: 1,
					// @ts-ignore
					gap: 16,
				}}
			>
				<Fab size="medium" variant="extended" color="default" aria-label="add" onClick={() => toggleImport(true)}>
					<CloudUpload style={{ marginRight: 8 }} />
					{t('common:import')}
				</Fab>
			</Box>

			<LevelMapsList levelMaps={levelMaps} edit={edit} onDelete={onDelete} />

			{confirmDeleteModal && modifyQuantity === 0 && (
				<ConfirmationDialog
					onClose={confirmDelete}
					title={t('levelMaps:delete_levelMap')}
					description={dialogDescription}
				/>
			)}

			{confirmDeleteModal && modifyQuantity > 0 && (
				<ConfirmationDialog
					confirmButton={t('common:cancel')}
					onClose={() => {
						history.push(`/items?levelMap=${levelMap.code}`);
					}}
					hideCancel={true}
					title={t('system:errorTryingDelete')}
				>
					<Box>
						<DialogContentText>{t('levelMaps:existent_items_on_delete_title')}.</DialogContentText>
						<DialogContentText>{dialogDescription}</DialogContentText>
					</Box>
				</ConfirmationDialog>
			)}

			{!loading && success && (
				<Alert
					message={t('common:success')}
					show={success}
					type={AlertsTypes.success}
					onClose={() => setSuccess(false)}
				/>
			)}

			{!loading && deleted && (
				<Alert
					message={t('common:deleted')}
					show={deleted}
					type={AlertsTypes.success}
					onClose={() => setDeleted(false)}
				/>
			)}

			{!loading && error && (
				<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />
			)}
		</ScreenContainer>
	);
}

export default LevelMaps;
