import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	makeStyles,
	Modal,
	createStyles,
	Box,
	Button,
	FormGroup,
	TextField,
	Typography,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';

import UserList from 'components/UserList';
import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';

import useUsers from 'hooks/useUsers';
import { TeacherError, refreshTeachers } from 'lib/models/schools';
import useSchools from 'hooks/useSchools';
import { useHistory } from 'react-router-dom';
import ConfirmationDialog from 'components/ConfirmationDialog';

function Teachers() {
	const { t } = useTranslation();
	const classes = useStyles();

	const { loading: loadingSchools, schools } = useSchools();
	const { loading: loadingTeachers, users, addUsers, setLoading } = useUsers('teacher');
	const [showRefreshModal, setShowRefreshModal] = useState(false);
	const [errors, setErrors] = useState<TeacherError[]>([]);

	const [all, setAll] = useState(false);

	const loading = loadingSchools || loadingTeachers;
	const history = useHistory();

	const refresh = useCallback(
		async (hubspotId?: string | false) => {
			if (typeof hubspotId === 'boolean' || (hubspotId && hubspotId.trim() !== '')) {
				setLoading(true);
				setErrors([]);
				try {
					const { users, errors: teacherErrors } = await refreshTeachers(hubspotId);
					if (users.length) {
						addUsers(users);
					}
					if (teacherErrors.length) {
						setErrors(teacherErrors);
					}
				} catch (error) {
					console.error(error);
				}
			}
			setShowRefreshModal(false);
			setLoading(false);
		},
		[setLoading, addUsers]
	);

	return (
		<ScreenContainer title={t('general:teacher_plural')}>
			{!!loading && <LinearProgress />}

			<UserList
				onSelect={(user) =>
					history.push(`schools/${schools?.find((school) => school.hubspotId === user.schoolHubspotId)?._id}`)
				}
				users={users}
				loading={loading}
				editUrl="teachers"
				customAction={{
					'aria-label': t('common:refresh'),
					icon: RefreshIcon,
					title: t('common:refresh'),
					onClick: () => setShowRefreshModal(true),
				}}
			/>

			{!!errors.length && (
				<ConfirmationDialog
					title={t('schools:syncTeachersErrorsTitle')}
					onClose={() => setErrors([])}
					loading={false}
					warningMessage={t('schools:syncAllTeachersErrorWarningText')}
				>
					<div className={classes.errorsContainer}>
						{errors.map((error) => {
							return (
								<div className={classes.errorContainer}>
									<Typography key={error.id}>
										{error.email} ({error.id}): {error.error}
									</Typography>
								</div>
							);
						})}
					</div>
				</ConfirmationDialog>
			)}

			<Modal open={!!showRefreshModal} onClose={() => {}} className={classes.modal}>
				<PaperContainer className={classes.modalChild}>
					{loading && <LinearProgress />}
					<Box>
						{!loading && (
							<Box className={classes.closeButtonContainer}>
								<Button
									variant="contained"
									color="secondary"
									size="large"
									className={classes.closeButton}
									startIcon={<CloseIcon />}
									onClick={() => setShowRefreshModal(false)}
								>
									{t('common:close')}
								</Button>
							</Box>
						)}

						<FormGroup>
							<Typography variant="body1" className={classes.title}>
								{t('schools:syncTitle')}
							</Typography>

							{(!loading || all) && (
								<FormControlLabel
									control={
										<Checkbox checked={all} onChange={() => setAll((current) => !current)} name="all" color="primary" />
									}
									label={t('common:all')}
									className={classes.checkBox}
									disabled={loading}
								/>
							)}

							{!all && (
								<Autocomplete
									fullWidth={true}
									onChange={(_, value) => refresh(value?.hubspotId)}
									id="combo-box-teachers"
									options={schools}
									getOptionLabel={(school: School) => school.name}
									renderInput={(params) => <TextField {...params} label={t('common:school')} variant="outlined" />}
									disabled={all || loading}
								/>
							)}

							{all && (
								<Button onClick={() => refresh(false)} variant="contained" color="primary" disabled={loading}>
									{t('common:search')} {t('common:all')}
								</Button>
							)}

							{all && (
								<Typography variant="body1" className={classes.descriptionWarning}>
									{t('schools:syncAllWarningText')}
								</Typography>
							)}

							<Typography variant="body2" className={classes.description}>
								{t('schools:syncText')}
							</Typography>

							{loading && (
								<Typography variant="body2" className={classes.description}>
									{t('common:loading')}
								</Typography>
							)}
						</FormGroup>
					</Box>
				</PaperContainer>
			</Modal>
		</ScreenContainer>
	);
}

export default Teachers;

const useStyles = makeStyles((theme) =>
	createStyles({
		input: {
			// flexGrow: 1,
			marginBottom: theme.spacing(2),
		},
		modal: {
			width: '100%',
			height: '100%',
			maxWidth: '1200px',
			margin: 'auto',
			marginTop: theme.spacing(2),
		},
		modalChild: {
			padding: theme.spacing(2),
		},
		closeButtonContainer: {
			display: 'flex',
			flexDirection: 'row',
			marginBottom: theme.spacing(1),
			justifyContent: 'flex-end',
		},
		closeButton: {},
		title: {
			color: theme.palette.text.primary,
			marginBottom: theme.spacing(1),
		},
		description: {
			color: theme.palette.text.hint,
			marginBottom: theme.spacing(1),
			marginTop: theme.spacing(1),
		},
		descriptionWarning: {
			color: theme.palette.warning.main,
			marginBottom: theme.spacing(1),
			marginTop: theme.spacing(1),
		},
		checkBox: {
			// marginLeft: theme.spacing(1),
		},
		errorsContainer: {
			overflow: 'auto',
		},
		errorContainer: {
			width: '100%',
			marginBottom: theme.spacing(2),
		},
	})
);
