const curriculums = {
	curriculum_plural: 'Curriculums',
	add_curriculum: 'Agregar curriculum',
	edit_curriculum: 'Editar curriculum',
	edit_curriculum_description: 'Se actualizará el curriculum asignado de {{quantity}} libros.',
	delete_curriculum: 'Borrar el curriculum seleccionado?',
	delete_curriculum_description: 'El curriculum sera eliminado de {{quantity}} items',
	existent_items_on_delete_title: 'Existen elementos asignados con este curriculum',
	existent_items_on_delete_description: 'Modifica el curriculum de los items antes de eliminar el curriculum.',
} as const;

export default curriculums;
