const general = {
	// General
	account: 'cuenta',
	account_plural: 'cuentas',
	dashboard: 'escritorio',
	exit: 'salida',
	favorite: 'favorito',
	favorite_plural: 'favoritos',
	id: 'ID',
	inbox: 'buzón de entrada',
	outbox: 'buzón de salida',
	personal_area: 'Perfil',
	profile: 'perfil',
	settings: 'configuraciones',
	user: 'usuario',
	user_plural: 'usuarios',
	user_me: 'mis datos',
	userTitle: 'datos de {{name}}',
	showTable: 'Mostrar tabla',
	showCalendar: 'Mostrar calendario',
	school: 'Escuela',
	school_plural: 'Escuelas',
	teacher: 'Docente',
	teacher_plural: 'Docentes',
	item: 'Item',
	item_plural: 'Items',
	list: 'Lista',
	list_plural: 'Listas',
	component_plural: 'Componentes',
	curriculum_plural: 'Curriculums',
	certificate_plural: 'Certificados',
	add_component: 'Agregar componente',
	add_subject: 'Agregar materia',
	add_curriculum: 'Agregar curriculum',
	add_certificate: 'Agregar certificado',
	edit_component: 'Editar componente',
	edit_subject: 'Editar materia',
	edit_curriculum: 'Editar curriculum',
	edit_certificate: 'Editar certificado',
	deleted: 'Borrado',
} as const;

export default general;
