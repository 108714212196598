export const DRAWER_WIDTH = 240;

export const APPBAR_MAX_HEIGHT = 128;

// IF YOU ARE IN DEV MODE API_DOMAIN IS SET IN .env
let API_DOMAIN = `${process.env.REACT_APP_API_DOMAIN}`;

// Check if the last index is a /, if not add it:
if (API_DOMAIN.substring(API_DOMAIN.length - 1) !== '/') {
	API_DOMAIN = `${API_DOMAIN}/`;
}

const AUTH_URL = 'auth';

const LOGIN_URL = `${AUTH_URL}/login`; // axios baseURL is ${API_DOMAIN}

const RECOVERY_URL = `${AUTH_URL}/password-recovery`; // axios baseURL is ${API_DOMAIN}

const RECOVERY_CONFIRM_URL = `${AUTH_URL}/password-recovery/confirm`; // axios baseURL is ${API_DOMAIN}

const ME_URL = `${AUTH_URL}/me`;

const FIRST_TIME_URL = `${AUTH_URL}/first-time`;

const USERS_URL = 'users';

const CYCLES_URL = 'cycles';

const SCHOOLS_URL = 'schools';

const LISTS_URL = 'lists';

const COMPONENTS_URL = 'components';

const SUBJECTS_URL = 'subjects';

const CURRICULUMS_URL = 'curriculums';

const CERTIFICATES_URL = 'certificates';

const GENRES_URL = 'genres';
const LEVELMAP_URL = 'levelMap';

const FORMATS_URL = 'formats';

const SEARCH_ITEMS_URL = 'search';

const ITEMS_URL = 'items';

const ITEM_TYPE_URL = 'itemTypes';

const SERIES_URL = 'series';

const PROFILE_URL = 'profile';

const ADMIN_URL = 'admin';

const SITE_CONFIGURATION_URL = 'config';

const rowsPerPageOptions = [25, 50, 100, 500, 1000];

const defaultRowsPerPage = 50;

const PANEL_MANUAL_URL = `${process.env.REACT_APP_PANEL_MANUAL_URL}`;

const IT_MANUAL_URL = `${process.env.REACT_APP_IT_MANUAL_URL}`;

const SUPPORTED_LANGUAGES: SupportedLanguage[] = ['es', 'en'];

const TEACHERS_URL = 'teachers';

export {
	API_DOMAIN,
	AUTH_URL,
	ME_URL,
	USERS_URL,
	SCHOOLS_URL,
	LISTS_URL,
	COMPONENTS_URL,
	SUBJECTS_URL,
	CURRICULUMS_URL,
	CERTIFICATES_URL,
	CYCLES_URL,
	GENRES_URL,
	SEARCH_ITEMS_URL,
	ITEMS_URL,
	ITEM_TYPE_URL,
	FORMATS_URL,
	SERIES_URL,
	LOGIN_URL,
	FIRST_TIME_URL,
	rowsPerPageOptions,
	defaultRowsPerPage,
	PANEL_MANUAL_URL,
	IT_MANUAL_URL,
	PROFILE_URL,
	SUPPORTED_LANGUAGES,
	LEVELMAP_URL,
	TEACHERS_URL,
	ADMIN_URL,
	RECOVERY_URL,
	RECOVERY_CONFIRM_URL,
	SITE_CONFIGURATION_URL,
};
