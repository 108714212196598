const series = {
	serie: 'Serie',
	serie_plural: 'Series',
	add_serie: 'Add serie',
	edit_serie: 'Edit serie',
	edit_serie_description: 'The serie of {{quantity}} books will be updated',
	delete_serie: 'Delete selected serie?',
	delete_serie_description: 'The serie will be removed from {{quantity}} items',
} as const;

export default series;
