const series = {
	serie: 'Serie',
	serie_plural: 'Series',
	add_serie: 'Agregar serie',
	edit_serie: 'Editar serie',
	edit_serie_description: 'Se actualizará la serie asignada de {{quantity}} libros.',
	delete_serie: 'Borrar la serie seleccionada?',
	delete_serie_description: 'La serie será eliminada de {{quantity}} items',
} as const;

export default series;
