import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	useTheme,
	ListItemText,
	useMediaQuery,
	TableHead,
	TableRow,
	TableCell,
	Button,
	CircularProgress,
	Box,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/icons/Face';

import { sanitizedInput } from 'lib/helpers';
import { inviteUsers } from 'lib/models/users';

import SearchToolbar from 'components/SearchToolbar';
import ResponsiveTable from 'components/ResponsiveTable';
import FullLayout from 'components/layouts/FullLayout';
import CustomTooltip, { CustomTooltipProps } from 'components/tooltips/CustomTooltip/CustomTooltip';

import useSchools from 'hooks/useSchools';

import { rowsPerPageOptions } from 'config/constants';
import { UsersTypes } from 'types/global';

type EditCallback = (user: User) => any;

type EditConfig = { callback: EditCallback; action: CustomTooltipProps };

type Props = {
	loading?: boolean;
	onSelect?(user: User): any;
	onEdit?: EditCallback | EditConfig;
	users: User[];
	paper?: boolean;
	disabled?: boolean;
	refetch?: () => {};
	customAction?: CustomTooltipProps;
};

export default function UserTable(props: Props) {
	const { t } = useTranslation();

	const classes = useStyles();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const [search, setSearch] = useState('');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(25);
	const { loading, schools } = useSchools();

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const searchValue = sanitizedInput(search);

	const users = props.users.filter((user) => {
		return (
			!searchValue ||
			sanitizedInput(user.first_name).includes(searchValue) ||
			sanitizedInput(user.last_name).includes(searchValue)
		);
	});

	const withTeachers =
		users.length && (users[0]!.type === UsersTypes.teacher || users[0]!.type === UsersTypes.teacherAdmin);

	function _onEdit(user: User) {
		if (!!props.onEdit) {
			if (isEditConfig(props.onEdit)) {
				props.onEdit.callback(user);
			} else {
				props.onEdit(user);
			}
		}
	}
	return (
		<FullLayout paper={props.paper}>
			<SearchToolbar
				onSearch={(value) => setSearch(value)}
				live={true}
				disabled={props.disabled}
				customAction={props.customAction}
			/>

			{!props.disabled && (
				<ResponsiveTable
					elements={isMobile ? users : users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
					list={{
						primaryKey: '_id',
						avatar: <Avatar />,
						renderListItemText: (user) => {
							const onClickProps = !!props.onSelect ? { onClick: () => props.onSelect!(user) } : {};
							const school = schools.find((school) => school.hubspotId === user.schoolHubspotId);
							return (
								<ListItemText
									{...onClickProps}
									primary={`${user.first_name || ''} ${user.last_name || ''} ${
										isPanelUser(user) ? `(${user.username})` : ''
									}`}
									secondary={
										<Box>
											{!props.onEdit && withTeachers && (
												<Box>
													{loading && <CircularProgress />}
													{!loading && (
														<a target={'_self'} href={`schools/${school?._id}`}>
															{school?.name || ''}
														</a>
													)}
												</Box>
											)}
											<address>
												<a href={`mailto:${user.email}`} target="_blank" rel="noreferrer">
													{user.email}
												</a>
											</address>
										</Box>
									}
								/>
							);
						},
					}}
					table={{
						renderHead: () => (
							<TableHead>
								<TableRow>
									<TableCell align="left" padding="normal">
										{t('common:firstName')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:lastName')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:role')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:email')}
									</TableCell>
									{!props.onEdit && !!withTeachers && (
										<TableCell align="left" padding="normal">
											{t('common:school')}
										</TableCell>
									)}
									<TableCell align="left" padding="normal">
										{t('common:status')}
									</TableCell>
									{(!!props.onEdit || !!props.onSelect) && (
										<TableCell align="left" padding="normal">
											{t('common:actions')}
										</TableCell>
									)}
								</TableRow>
							</TableHead>
						),
						renderRow: (user) => {
							// const onClickProps = !!props.onSelect ? { onClick: () => props.onSelect!(user) } : {};
							const school = schools.find((school) => school.hubspotId === user.schoolHubspotId);
							return (
								<TableRow tabIndex={-1} key={user._id} className={classes.row}>
									<TableCell>
										{user.first_name} {`${isPanelUser(user) ? `(${user.username})` : ''}`}
									</TableCell>
									<TableCell>{user.last_name}</TableCell>
									<TableCell>{t(`common:role_${user.type}`)}</TableCell>
									<TableCell>
										<address>
											<a href={`mailto:${user.email}`} target="_blank" rel="noreferrer">
												{user.email}
											</a>
										</address>
									</TableCell>
									{!props.onEdit && withTeachers && (
										<TableCell>
											{loading && (user.type === UsersTypes.teacherAdmin || user.type === UsersTypes.teacher) && (
												<CircularProgress />
											)}
											{!loading && (user.type === UsersTypes.teacherAdmin || user.type === UsersTypes.teacher) && (
												<a target={'_self'} href={`schools/${school?._id}`}>
													{school?.name || ''}
												</a>
											)}
											{(user.type === UsersTypes.admin || user.type === UsersTypes.panel) && <span>{user.type}</span>}
										</TableCell>
									)}
									<TableCell>
										{(user.type === UsersTypes.teacherAdmin || user.type === UsersTypes.teacher) && (
											<UserStatus user={user} refetch={props.refetch} />
										)}
										{(user.type === UsersTypes.admin || user.type === UsersTypes.panel) && (
											<span className={classes.status}>{t(`common:${user.status}`)}</span>
										)}
									</TableCell>
									{!!props.onSelect && !props.onEdit && (
										<TableCell>
											<Box>
												<Button onClick={() => props.onSelect!(user)} variant="outlined">
													{t(`common:edit`)}
												</Button>
											</Box>
										</TableCell>
									)}
									{!!props.onEdit && (
										<TableCell>
											{!isEditConfig(props.onEdit) && (
												<Box>
													<Button onClick={() => _onEdit(user)} variant="outlined">
														{t(`common:edit`)}
													</Button>
												</Box>
											)}
											{isEditConfig(props.onEdit) && (
												<CustomTooltip {...props.onEdit.action} onClick={() => _onEdit(user)} />
											)}
										</TableCell>
									)}
								</TableRow>
							);
						},
						pagination: props.disabled
							? null
							: {
									count: users.length,
									page,
									rowsPerPage,
									rowsPerPageOptions,
									onPageChange: handleChangePage,
									onRowsPerPageChange: handleChangeRowsPerPage,
							  },
					}}
				/>
			)}
		</FullLayout>
	);
}

type UserStatusProps = {
	user: User;
	refetch?: () => any;
};

function UserStatus({ user, refetch }: UserStatusProps) {
	const { t } = useTranslation();
	const classes = useStyles();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const send = useCallback(async () => {
		try {
			setLoading(true);
			await inviteUsers(user);
			setLoading(false);
			setError(false);
		} catch (error) {
			setError(true);
		}
		setLoading(false);
		refetch?.();
	}, [refetch, user]);

	if (loading) {
		return <CircularProgress />;
	}
	if (user.status === 'inactive' || user.status === 'invited') {
		return (
			<Box>
				{user.status === 'invited' && <span className={classes.status}>{t(`common:${user.status}`)}</span>}
				<Button onClick={send} variant="outlined">
					{t(`common:update_${error ? 'again' : user.status}`)}
				</Button>
			</Box>
		);
	}
	return <span className={classes.status}>{t(`common:${user.status}`)}</span>;
}

const useStyles = makeStyles((theme) =>
	createStyles({
		row: {},
		status: {
			marginRight: theme.spacing(1),
		},
	})
);

function isEditConfig(data?: EditConfig | EditCallback): data is EditConfig {
	return !!data && typeof data !== 'function';
}

function isPanelUser(user: User): boolean {
	return user.type === UsersTypes.admin || user.type === UsersTypes.panel;
}
