import { fetchCurriculums } from 'lib/models/curriculums';
import { useCallback, useEffect, useState } from 'react';

function useCurriculums() {
	const [loading, setLoading] = useState<boolean>(true);
	const [curriculums, setCurriculums] = useState<CurriculumType[]>([]);

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchCurriculums();
		setCurriculums(response);
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, curriculums, refetch };
}

export default useCurriculums;
