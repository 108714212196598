import { ComponentsSearchParams, fetchComponents } from 'lib/models/components';
import { useCallback, useEffect, useState } from 'react';

const defaultSearch: ComponentsSearchParams = {
	types: [],
	text: '',
};

function useComponents() {
	const [loading, setLoading] = useState<boolean>(true);
	const [components, setComponents] = useState<ComponentType[]>([]);

	const refetch = useCallback(async (params: ComponentsSearchParams = defaultSearch) => {
		setLoading(true);
		const response = await fetchComponents(params);

		setComponents(response);
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, setLoading, components, refetch };
}

export default useComponents;
