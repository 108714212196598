const genres = {
	genre_plural: 'Genres',
	add_genre: 'Add genre',
	edit_genre: 'Edit genre',
	edit_genre_description: 'The genre of {{quantity}} books will be updated',
	delete_genre: 'Delete selected genre?',
	delete_genre_description: 'The genre will be removed from {{quantity}} items',
	existent_items_on_delete_title: 'There are items assigned with this genre',
	existent_items_on_delete_description: 'Modify the items with this genre before deleting the genre.',
} as const;

export default genres;
