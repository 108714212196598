const schools = {
	notFound: 'School {{name}} not found',
	deleteTitle: 'School deletion',
	deleteText: 'Delete selected school?',
	deleteText_plural: 'Delete selected schools?',
	confirmImportTitle: 'Import confirmation',
	confirmImportDescription: '{{totalToImport}} of {{amount}} schools will be imported',
	importedDescription: '{{importedAmount}} schools imported',
	invalidSchool: 'School in row ({{index}})',
	invalidSchools: 'Invalid schools ({{amount}})',
	importDescriptionWarning: 'All schools to import that has the same name will be replaced.',
	invalidFile: 'Invalid file, columns with name "name" and "levels" are needed',
	uploadUsersError: "This users weren't updated ({{schools}})",
	createSchool: 'Create school',
	editSchool: 'Edit school',
	creationSuccessTitle: 'School created',
	creationSuccessDescription: 'The "{{name}}" school was successfully created.',
	level: 'Level',
	teachers: 'Teachers',
	firstCreateSchoolMessage: 'You must first create the school before adding teachers.',
	search: 'Search school',
	syncTitle: 'Sync users',
	syncText:
		'Warning, synchronizing users with "Hubspot" will consume part of your system access fee, do you want to continue with the update?',
	syncSchoolsTitle: 'Sync schools',
	syncSchoolsText:
		'Warning, synchronizing schools with "Hubspot" will consume part of your system access fee, do you want to continue with the update?',
	syncButton: 'Yes, continue',
	warningUploader:
		'WARNING: If the file you are uploading contains schools that are already present in the database, it will overwrite them with the new values ​​and any changes you have made to them will be lost.',
	syncNewSchools: 'Search for new schools in hubspot',
	syncSchoolsCurrentPage: 'Update the current page with data obtained from hubspot',
	syncAllWarningText: 'WARNING: This update will execute multiple requests to "hubspot" and may take several minutes',
	syncSchoolsErrorsTitle: 'School synchronization errors',
	syncAllErrorWarningText: 'The following schools could not be imported/updated due to the errors listed below:',
	syncTeachersErrorsTitle: 'Teachers synchronization errors',
	syncAllTeachersErrorWarningText:
		'The following teachers could not be imported/updated due to the errors listed below:',
};

export default schools;
