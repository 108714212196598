import { useTranslation } from 'react-i18next';

// import { useHistory } from 'react-router-dom';

import ScreenContainer from 'components/containers/ScreenContainer';
import {
	Box,
	Button,
	Input,
	InputLabel,
	LinearProgress,
	Typography,
	createStyles,
	makeStyles,
} from '@material-ui/core';

import DocIcon from '@material-ui/icons/FindInPage';
import IconCard from 'components/IconCard';
import useLoggedUser from 'hooks/useLoggedUser';
import { PANEL_MANUAL_URL, IT_MANUAL_URL } from 'config/constants';

import useSiteConfiguration from './useSiteConfiguration';
import { UsersTypes } from 'types/global';
import { Add, Delete } from '@material-ui/icons';
import ColorPicker from 'components/ColorPicker';

function Dashboard() {
	const { t } = useTranslation();

	const classes = useStyles();

	// const history = useHistory();

	const user = useLoggedUser();

	const { config, loading, save, updateInstructivePdfUrl, addStockAmounts, updateStockAmounts, removeStockAmount } =
		useSiteConfiguration();

	return (
		<ScreenContainer title={t('general:dashboard')} className={classes.root}>
			<Box>
				<Typography variant="body1">{t('common:welcome', { name: user?.first_name || '' })}</Typography>
			</Box>
			<Box className={classes.cards}>
				<a href={PANEL_MANUAL_URL} target="_blank" rel="noreferrer" className={classes.link}>
					<IconCard
						title={t('common:backOfficeDocumentationTitle')}
						description={t('common:backOfficeDocumentationDescription')}
						icon={<DocIcon />}
					/>
				</a>
				<a href={IT_MANUAL_URL} target="_blank" rel="noreferrer" className={classes.link}>
					<IconCard
						title={t('common:itDocumentationTitle')}
						description={t('common:itDocumentationDescription')}
						icon={<DocIcon />}
					/>
				</a>
			</Box>
			{user?.type === UsersTypes.admin && (
				<Box className={classes.form}>
					<Typography variant="h6">{t('common:siteConfig')}</Typography>
					<Typography variant="body2" className={classes.mt}>
						{t('common:instructiveURL')}
					</Typography>
					{loading && <LinearProgress />}
					<Input
						className={classes.mt}
						value={config.instructivePdfUrl}
						onChange={(ev) => updateInstructivePdfUrl(ev.currentTarget.value)}
						fullWidth
						disabled={loading}
					/>
					<Typography variant="body1" className={classes.mt} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
						{t('common:stockValues')}
						<Button onClick={() => addStockAmounts('', 0, '#3f51b5', '#ffffff')}>
							<Add />
						</Button>
					</Typography>

					<Typography variant="body2" className={classes.mt}>
						{t('common:stockDescription')}
					</Typography>

					{config.stockAmounts?.map((stockAmount, index) => {
						const keyBase = `stockAmount-${index}`;
						return (
							<div
								key={`${keyBase}-container`}
								style={{
									display: 'flex',
									gap: '32px',
									marginTop: '16px',
									flexWrap: 'wrap',
									backgroundColor: '#f6f6f6',
									padding: '24px',
									borderRadius: '12px',
									boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.08)',
								}}
							>
								<div>
									<InputLabel id={`${keyBase}-name-label`}>{t('common:text')}</InputLabel>
									<Input
										id={`${keyBase}-name`}
										className={classes.mt}
										value={stockAmount.text}
										onChange={(ev) =>
											updateStockAmounts(
												ev.currentTarget.value,
												stockAmount.minAmount,
												stockAmount.backgroundColor,
												stockAmount.textColor,
												index
											)
										}
										disabled={loading}
										style={{ minWidth: 250 }}
									/>
								</div>
								<div>
									<InputLabel id={`${keyBase}-amount-label`}>{t('common:minValue')}</InputLabel>
									<Input
										id={`${keyBase}-amount`}
										className={classes.mt}
										value={stockAmount.minAmount}
										onChange={(ev) =>
											updateStockAmounts(
												stockAmount.text,
												Number(ev.currentTarget.value),
												stockAmount.backgroundColor,
												stockAmount.textColor,
												index
											)
										}
										disabled={loading}
										inputProps={{ type: 'numeric' }}
									/>
								</div>
								<div>
									<InputLabel id={`${keyBase}-color-label`}>{t('common:backgroundColor')}</InputLabel>
									<ColorPicker
										keyBase={keyBase}
										style={{ marginTop: 16 }}
										size={30}
										value={stockAmount.backgroundColor}
										disabled={loading}
										onChange={(ev) =>
											updateStockAmounts(
												stockAmount.text,
												stockAmount.minAmount,
												ev.currentTarget.value,
												stockAmount.textColor,
												index
											)
										}
									/>
								</div>
								<div>
									<InputLabel id={`${keyBase}-color-label`}>{t('common:textColor')}</InputLabel>
									<ColorPicker
										keyBase={keyBase}
										style={{ marginTop: 16 }}
										size={30}
										value={stockAmount.textColor}
										disabled={loading}
										onChange={(ev) =>
											updateStockAmounts(
												stockAmount.text,
												stockAmount.minAmount,
												stockAmount.backgroundColor,
												ev.currentTarget.value,
												index
											)
										}
									/>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'flex-end',
										alignItems: 'center',
										flexGrow: 1,
									}}
								>
									<Delete color="error" style={{ cursor: 'pointer' }} onClick={() => removeStockAmount(index)} />
								</div>
							</div>
						);
					})}

					<Button onClick={save} variant="contained" color="primary" className={classes.mt} disabled={loading}>
						{t('common:save')}
					</Button>
				</Box>
			)}
		</ScreenContainer>
	);
}

export default Dashboard;

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		cards: {
			display: 'flex',
		},
		link: {
			textDecoration: 'none',
		},
		form: {
			backgroundColor: theme.palette.common.white,
			padding: theme.spacing(2),
			borderRadius: theme.spacing(2),
		},
		mt: {
			marginTop: theme.spacing(2),
		},
	})
);
