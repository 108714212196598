import { API_DOMAIN, FORMATS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function fetchFormats(): Promise<FormatType[]> {
	try {
		const response = await api.get<FormatType[]>(`${API_DOMAIN}${FORMATS_URL}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}
export async function createFormat(format: FormatType): Promise<FormatType | null> {
	try {
		const data = { ...format, name: format.name.trim() };
		const response = await api.post<FormatType>(`${API_DOMAIN}${FORMATS_URL}`, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateFormat(format: FormatType): Promise<FormatType | null> {
	if (!format._id) {
		delete format._id;
	}
	const data = { ...format, name: format.name.trim() };

	const url = `${API_DOMAIN}${FORMATS_URL}/${format._id}`;

	try {
		const response = await api.patch<FormatType>(url, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function deleteFormat(format: FormatType): Promise<{ success: boolean }> {
	const url = `${API_DOMAIN}${FORMATS_URL}/${format._id}`;

	try {
		const response = await api.delete(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return { success: true };
	} catch (error) {
		console.error(error);
		return { success: false };
	}
}

export async function askUpdateFormat(format: FormatType): Promise<{ items: number; success: boolean }> {
	const url = `${API_DOMAIN}${FORMATS_URL}/ask-quantity/${format._id}`;

	try {
		const response = await api.get<{ items: number; success: boolean }>(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return { items: 0, success: false };
	}
}
