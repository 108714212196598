import { API_DOMAIN, COMPONENTS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';
import { ItemTypes } from 'teachers-types';

export type ComponentsSearchParams = { types: ItemTypes[]; text?: string };

export async function fetchComponents(params: ComponentsSearchParams): Promise<ComponentType[]> {
	try {
		const response = await api.get<ComponentType[]>(`${API_DOMAIN}${COMPONENTS_URL}`, {
			params,
		});

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}
export async function createComponent(component: ComponentType): Promise<ComponentType | null> {
	try {
		const data = { ...component, name: component.name.trim() };

		const response = await api.post<ComponentType>(`${API_DOMAIN}${COMPONENTS_URL}`, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateComponent(component: ComponentType): Promise<ComponentType | null> {
	if (!component._id) {
		delete component._id;
	}

	const data = { ...component, name: component.name.trim() };

	const url = `${API_DOMAIN}${COMPONENTS_URL}/${component._id}`;

	try {
		const response = await api.patch<ComponentType>(url, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function deleteComponent(component: ComponentType): Promise<{ success: boolean }> {
	const url = `${API_DOMAIN}${COMPONENTS_URL}/${component._id}`;

	try {
		const response = await api.delete(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return { success: true };
	} catch (error) {
		console.error(error);
		return { success: false };
	}
}

export async function askDeleteComponent(component: ComponentType): Promise<{ items: number; success: boolean }> {
	const url = `${API_DOMAIN}${COMPONENTS_URL}/ask-quantity/${component._id}`;

	try {
		const response = await api.get<{ items: number; success: boolean }>(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		return { items: 0, success: false };
	}
}
