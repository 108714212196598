import {
	Card,
	Container,
	Modal,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	createStyles,
	makeStyles,
} from '@material-ui/core';
import CustomTooltip from 'components/tooltips/CustomTooltip';
import HideIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type Props = {
	list: List;
	open: boolean;
	onClose: () => any;
};
function ItemsModal({ open, list, onClose }: Props) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Modal open={open} className={classes.modal} onClose={onClose}>
			<Card className={classes.modalCard}>
				<Container className={classes.modalHeader}>
					<div className={classes.tooltipContainer}>
						<CustomTooltip aria-label={t('common:view')} title={t('common:view')} onClick={onClose} icon={HideIcon} />
					</div>
				</Container>
				<div className={classes.titleContainer}>
					<Typography variant="h6" className={classes.listTitle}>
						{list.name.toUpperCase()} - {list.levelMap?.description?.toUpperCase() || ''}
					</Typography>
					<Typography variant="body2" className={classes.createdAt}>
						{moment(list.createdAt).format('DD/MM/yyyy HH:mm')}
					</Typography>
				</div>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{t('common:image')}</TableCell>
							<TableCell>ISBN</TableCell>
							<TableCell>{t('common:code')} (KEL)</TableCell>
							<TableCell>{t('common:title')}</TableCell>
							<TableCell>{t('common:listMessage')}</TableCell>
							<TableCell>{t('common:amount')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{list.items.map((item) => (
							<TableRow key={`books-${item._id}`}>
								<TableCell>
									<img src={item.thumbnail} alt={''} width={50} height={50} />
								</TableCell>
								<TableCell>{item.isbn}</TableCell>
								<TableCell>{item.kelCode}</TableCell>
								<TableCell>{item.title}</TableCell>
								<TableCell>{list.message || ''}</TableCell>
								<TableCell>{list.amounts?.[item._id!] || list.studentsAmount}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Card>
		</Modal>
	);
}

const useStyles = makeStyles(() =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		modalCard: {
			width: '80%',
			maxHeight: '80%',
			overflowY: 'auto',
		},
		modalHeader: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			padding: '0px',
		},
		tooltipContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			width: '100%',
		},
		titleContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			padding: '0.5rem 14rem 0 3.5rem',
		},
		listTitle: {
			flex: 1,
		},
		createdAt: {
			textAlign: 'right',
			minWidth: '150px',
		},
	})
);

export default ItemsModal;
