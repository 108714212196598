import { fetchSeries } from 'lib/models/series';
import { useCallback, useEffect, useState } from 'react';

const defaultSearch: SeriesSearchParameters = {
	limit: 25,
	offset: 0,
	filters: {},
};

function useSeries() {
	const [loading, setLoading] = useState<boolean>(true);
	const [series, setSeries] = useState<SerieType[]>([]);
	const [count, setCount] = useState(0);

	const refetch = useCallback(async (search: SeriesSearchParameters = defaultSearch) => {
		setLoading(true);
		const response = await fetchSeries(search);
		if (response) {
			setSeries(response.data);
			setCount(response.count);
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, series, refetch, setLoading, count };
}

export default useSeries;
