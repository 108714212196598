import { useTranslation } from 'react-i18next';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';
import SubjectsList from 'components/SubjectsLists/SubjectsList';
import { useCallback, useEffect, useState } from 'react';
import { askUpdateSubject, createSubject, deleteSubject, fetchSubjects, updateSubject } from 'lib/models/subjects';
import ModalForm from 'components/ModalForm';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Alert, { AlertsTypes } from 'components/Alert';
import { useHistory } from 'react-router-dom';
import { Box, DialogContentText } from '@material-ui/core';

function Subjects() {
	const { t } = useTranslation();
	const history = useHistory();

	const [subjects, setSubjects] = useState<SubjectType[]>([]);

	const [subject, setSubject] = useState<SubjectType>({ name: '', id: '' });
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [modifyQuantity, setModifyQuantity] = useState(0);
	const [deleted, setDeleted] = useState(false);

	const [error, setError] = useState('');

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchSubjects();
		if (response) {
			setSubjects(response);
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	const edit = async (subject: SubjectType) => {
		const { success, items } = await askUpdateSubject(subject);
		if (success) {
			setModifyQuantity(items);
			setSubject(subject);
			setOpen(true);
		}
	};

	const onDelete = async (subject: SubjectType) => {
		const { success, items } = await askUpdateSubject(subject);
		if (success) {
			setModifyQuantity(items);
			setConfirmDeleteModal(true);
			setSubject(subject);
		}
	};

	const save = async () => {
		setLoading(true);
		try {
			subject._id ? await updateSubject(subject) : await createSubject(subject);
			setSuccess(true);
			refetch();
			closeModal();
		} catch (error) {
			console.error(error);
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
		resetAlert();
	};

	const onUpdate = async (value: string) => {
		setSubject((prev) => {
			return { ...prev, name: value };
		});
	};

	const confirmDelete = async (confirm: boolean) => {
		if (confirm) {
			try {
				await deleteSubject(subject);
				setDeleted(true);
				setConfirmDeleteModal(false);
				refetch();
			} catch (error: any) {
				console.error(error);
				setError(error.message || error);
			}
		} else {
			setSubject({ name: '', id: '' });
			setConfirmDeleteModal(false);
		}
		resetAlert();
	};

	const closeModal = () => {
		setOpen(false);
		setSubject({ name: '', id: '' });
		setModifyQuantity(0);
	};

	const resetAlert = () => {
		setTimeout(() => {
			setSuccess(false);
			setError('');
			setDeleted(false);
		}, 3000);
	};

	const dialogDescription =
		modifyQuantity > 0
			? t('subjects:existent_items_on_delete_description')
			: t('subjects:delete_component_description', { quantity: modifyQuantity });

	return (
		<ScreenContainer title={t('subjects:subject_plural')}>
			<CreateIcon
				onCreate={() => {
					setOpen(true);
				}}
			/>

			<SubjectsList subjects={subjects} edit={edit} onDelete={onDelete} />

			{confirmDeleteModal && modifyQuantity === 0 && (
				<ConfirmationDialog
					onClose={confirmDelete}
					title={t('subjects:delete_certificate')}
					description={dialogDescription}
				/>
			)}

			{confirmDeleteModal && modifyQuantity > 0 && (
				<ConfirmationDialog
					confirmButton={t('common:cancel')}
					onClose={() => {
						history.push(`/items?subject=${subject.name}`);
					}}
					hideCancel={true}
					title={t('system:errorTryingDelete')}
				>
					<Box>
						<DialogContentText>{t('subjects:existent_items_on_delete_title')}.</DialogContentText>
						<DialogContentText>{dialogDescription}</DialogContentText>
					</Box>
				</ConfirmationDialog>
			)}
			{open && (
				<ModalForm
					title={subject.id ? t('subjects:edit_subject') : t('subjects:add_subject')}
					item={subject}
					onUpdate={onUpdate}
					onSubmit={save}
					onCancel={closeModal}
					description={
						modifyQuantity > 0 ? t('subjects:edit_subject_description', { quantity: modifyQuantity }) : undefined
					}
				/>
			)}

			{!loading && success && (
				<Alert
					message={t('common:success')}
					show={success}
					type={AlertsTypes.success}
					onClose={() => setSuccess(false)}
				/>
			)}

			{!loading && deleted && (
				<Alert
					message={t('common:deleted')}
					show={deleted}
					type={AlertsTypes.success}
					onClose={() => setDeleted(false)}
				/>
			)}

			{!loading && error && (
				<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />
			)}
		</ScreenContainer>
	);
}

export default Subjects;
