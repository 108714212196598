import { searchLists } from 'lib/models/lists';
import { Moment } from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { ListStatus } from 'teachers-types';

function useLists(
	schoolHubspotId?: string | null,
	status?: ListStatus | null,
	level?: string | null,
	text?: string | null,
	from?: Moment | null,
	to?: Moment | null
) {
	const [loading, setLoading] = useState<boolean>(false);
	const [lists, setLists] = useState<List[]>([]);

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await searchLists({
			level,
			schoolHubspotId,
			status,
			text,
			from,
			to,
		});
		setLists(response);
		setLoading(false);
	}, [from, level, schoolHubspotId, status, text, to]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, lists, refetch };
}

export default useLists;
