const levelMaps = {
	levelMap_plural: 'Levels',
	add_levelMap: 'Add Level',
	edit_levelMap: 'Edit Level',
	edit_levelMap_description: 'The Level of {{quantity}} books will be updated',
	delete_levelMap: 'Delete selected Level?',
	delete_levelMap_description: 'The Level will be removed from {{quantity}} items',
	existent_items_on_delete_title: 'There are items assigned with this Level',
	existent_items_on_delete_description: 'Modify the items with this Level before deleting the Level.',
} as const;

export default levelMaps;
