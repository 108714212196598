import useSelectedLanguage from 'hooks/useSelectedLanguage';
import { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import 'moment/locale/es';

function Language({ children }: PropsWithChildren<{}>) {
	const { i18n } = useTranslation();

	const language = useSelectedLanguage();

	useEffect(() => {
		i18n.changeLanguage(language);
	}, [i18n, language]);

	console.debug('language: ', language);

	return (
		<MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
			{children}
		</MuiPickersUtilsProvider>
	);
}

export default Language;
