import { useTranslation } from 'react-i18next';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';
import { useCallback, useEffect, useState } from 'react';
import ModalForm from 'components/ModalForm';
import { askUpdateGenre, createGenre, deleteGenre, fetchGenres, updateGenre } from 'lib/models/genres';
import Alert, { AlertsTypes } from 'components/Alert';
import ConfirmationDialog from 'components/ConfirmationDialog';
import GenresList from 'components/GenresList';
import { useHistory } from 'react-router-dom';
import { Box, DialogContentText } from '@material-ui/core';

function Genres() {
	const history = useHistory();
	const { t } = useTranslation();

	const [genres, setGenres] = useState<GenreType[]>([]);

	const [genre, setGenre] = useState<GenreType>({ name: '', id: '' });
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [modifyQuantity, setModifyQuantity] = useState(0);
	const [deleted, setDeleted] = useState(false);

	const [error, setError] = useState('');

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchGenres();
		if (response) {
			setGenres(response);
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	const edit = async (genre: GenreType) => {
		const { success, items } = await askUpdateGenre(genre);
		if (success) {
			setModifyQuantity(items);
			setGenre(genre);
			setOpen(true);
		}
	};

	const onDelete = async (genre: GenreType) => {
		const { success, items } = await askUpdateGenre(genre);
		if (success) {
			setModifyQuantity(items);
			setConfirmDeleteModal(true);
			setGenre(genre);
		}
	};

	const save = async () => {
		setLoading(true);
		try {
			genre._id ? await updateGenre(genre) : await createGenre(genre);
			setSuccess(true);
			refetch();
			closeModal();
		} catch (error) {
			console.error(error);
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
		resetAlert();
	};

	const onUpdate = async (value: string) => {
		setGenre((prev) => {
			return { ...prev, name: value };
		});
	};

	const confirmDelete = async (confirm: boolean) => {
		if (confirm) {
			try {
				await deleteGenre(genre);
				setDeleted(true);
				setConfirmDeleteModal(false);
				refetch();
			} catch (error: any) {
				console.error(error);
				setError(error.message || error);
			}
		} else {
			setGenre({ name: '', id: '' });
			setConfirmDeleteModal(false);
		}
		resetAlert();
	};

	const closeModal = () => {
		setOpen(false);
		setGenre({ name: '', id: '' });
		setModifyQuantity(0);
	};

	const resetAlert = () => {
		setTimeout(() => {
			setSuccess(false);
			setError('');
			setDeleted(false);
		}, 3000);
	};

	const dialogDescription =
		modifyQuantity > 0
			? t('genres:existent_items_on_delete_description')
			: t('genres:delete_component_description', { quantity: modifyQuantity });
	return (
		<ScreenContainer title={t('genres:genre_plural')}>
			<CreateIcon
				onCreate={() => {
					setOpen(true);
				}}
			/>

			<GenresList genres={genres} edit={edit} onDelete={onDelete} />

			{confirmDeleteModal && modifyQuantity === 0 && (
				<ConfirmationDialog onClose={confirmDelete} title={t('genres:delete_genre')} description={dialogDescription} />
			)}

			{confirmDeleteModal && modifyQuantity > 0 && (
				<ConfirmationDialog
					confirmButton={t('common:cancel')}
					onClose={() => {
						history.push(`/items?genre=${genre.name}`);
					}}
					hideCancel={true}
					title={t('system:errorTryingDelete')}
				>
					<Box>
						<DialogContentText>{t('genres:existent_items_on_delete_title')}.</DialogContentText>
						<DialogContentText>{dialogDescription}</DialogContentText>
					</Box>
				</ConfirmationDialog>
			)}

			{open && (
				<ModalForm
					title={genre.id ? t('genres:edit_genre') : t('genres:add_genre')}
					item={genre}
					onUpdate={onUpdate}
					onSubmit={save}
					onCancel={closeModal}
					description={
						modifyQuantity > 0 ? t('genres:edit_genre_description', { quantity: modifyQuantity }) : undefined
					}
				/>
			)}
			{!loading && success && (
				<Alert
					message={t('common:success')}
					show={success}
					type={AlertsTypes.success}
					onClose={() => setSuccess(false)}
				/>
			)}

			{!loading && deleted && (
				<Alert
					message={t('common:deleted')}
					show={deleted}
					type={AlertsTypes.success}
					onClose={() => setDeleted(false)}
				/>
			)}

			{!loading && error && (
				<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />
			)}
		</ScreenContainer>
	);
}

export default Genres;
