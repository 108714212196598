import { useTranslation } from 'react-i18next';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';
import { useCallback, useEffect, useState } from 'react';
import ModalForm from 'components/ModalForm';
import { askUpdateFormat, createFormat, deleteFormat, fetchFormats, updateFormat } from 'lib/models/formats';
import Alert, { AlertsTypes } from 'components/Alert';
import ConfirmationDialog from 'components/ConfirmationDialog';
import FormatsList from 'components/FormatsList';
import { useHistory } from 'react-router-dom';
import { Box, DialogContentText } from '@material-ui/core';

function Formats() {
	const history = useHistory();
	const { t } = useTranslation();

	const [formats, setFormats] = useState<FormatType[]>([]);

	const [format, setFormat] = useState<FormatType>({ name: '', id: '' });
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
	const [modifyQuantity, setModifyQuantity] = useState(0);
	const [deleted, setDeleted] = useState(false);

	const [error, setError] = useState('');

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchFormats();
		if (response) {
			setFormats(response);
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	const edit = async (format: FormatType) => {
		const { success, items } = await askUpdateFormat(format);
		if (success) {
			setModifyQuantity(items);
			setFormat(format);
			setOpen(true);
		}
	};

	const onDelete = async (format: FormatType) => {
		const { success, items } = await askUpdateFormat(format);
		if (success) {
			setModifyQuantity(items);
			setConfirmDeleteModal(true);
			setFormat(format);
		}
	};

	const save = async () => {
		setLoading(true);
		try {
			format._id ? await updateFormat(format) : await createFormat(format);
			setSuccess(true);
			refetch();
			closeModal();
		} catch (error) {
			console.error(error);
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
		resetAlert();
	};

	const onUpdate = async (value: string) => {
		setFormat((prev) => {
			return { ...prev, name: value };
		});
	};

	const confirmDelete = async (confirm: boolean) => {
		if (confirm) {
			try {
				await deleteFormat(format);
				setDeleted(true);
				setConfirmDeleteModal(false);
				refetch();
			} catch (error: any) {
				console.error(error);
				setError(error.message || error);
			}
		} else {
			setFormat({ name: '', id: '' });
			setConfirmDeleteModal(false);
		}
		resetAlert();
	};

	const closeModal = () => {
		setOpen(false);
		setFormat({ name: '', id: '' });
		setModifyQuantity(0);
	};

	const resetAlert = () => {
		setTimeout(() => {
			setSuccess(false);
			setError('');
			setDeleted(false);
		}, 3000);
	};

	const dialogDescription =
		modifyQuantity > 0
			? t('formats:existent_items_on_delete_description')
			: t('formats:delete_component_description', { quantity: modifyQuantity });
	return (
		<ScreenContainer title={t('formats:format_plural')}>
			<CreateIcon
				onCreate={() => {
					setOpen(true);
				}}
			/>

			<FormatsList formats={formats} edit={edit} onDelete={onDelete} />

			{confirmDeleteModal && modifyQuantity === 0 && (
				<ConfirmationDialog
					onClose={confirmDelete}
					title={t('formats:delete_format')}
					description={dialogDescription}
				/>
			)}

			{confirmDeleteModal && modifyQuantity > 0 && (
				<ConfirmationDialog
					confirmButton={t('common:cancel')}
					onClose={() => {
						history.push(`/items?format=${format.name}`);
					}}
					hideCancel={true}
					title={t('system:errorTryingDelete')}
				>
					<Box>
						<DialogContentText>{t('formats:existent_items_on_delete_title')}.</DialogContentText>
						<DialogContentText>{dialogDescription}</DialogContentText>
					</Box>
				</ConfirmationDialog>
			)}

			{open && (
				<ModalForm
					title={format.id ? t('formats:edit_format') : t('formats:add_format')}
					item={format}
					onUpdate={onUpdate}
					onSubmit={save}
					onCancel={closeModal}
					description={
						modifyQuantity > 0 ? t('formats:edit_format_description', { quantity: modifyQuantity }) : undefined
					}
				/>
			)}
			{!loading && success && (
				<Alert
					message={t('common:success')}
					show={success}
					type={AlertsTypes.success}
					onClose={() => setSuccess(false)}
				/>
			)}

			{!loading && deleted && (
				<Alert
					message={t('common:deleted')}
					show={deleted}
					type={AlertsTypes.success}
					onClose={() => setDeleted(false)}
				/>
			)}

			{!loading && error && (
				<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />
			)}
		</ScreenContainer>
	);
}

export default Formats;
