const system = {
	// System
	errorsUsername: 'ingresar usuario',
	errorsPassword: 'ingresar contraseña',
	login: 'ingresar',
	loginError: 'nombre de usuario o clave incorrecta',
	logout: 'salir',
	logoutModalText: '¿cerrar sessión?',
	nextPage: 'página siguiente',
	previousPage: 'página anterior',
	profile: 'perfil',
	print: 'imprimir',
	preview: 'vista previa',
	rememberUser: '¿recordar usuario?',
	rowsPerPage: 'cantidad por página',
	requestError: 'hubo un problema de comunicación, intente nuevamente.',
	requestFinalError: 'el sistema se encuentra fuera de servicio, intente nuevamente mas tarde.',
	sortAscending: 'ordenado ascendente',
	sortDescending: 'ordenado descendente',
	titlesNoMatch: 'dirección no encontrada',
	titlesUnauthorized: 'acceso no autorizado',
	firstTimeTitle: 'Primera vez',
	firstTimeDescription:
		'Antes de empezar es necesario asignarle una clave al usuario administrador, para eso ingresá al sistema utilizando en nombre de usuario "admin" y una clave a elección.',
	forgot: 'Olvidé mi contraseña',
	recoveryMessage: 'Ingrese su nombre de usuario y su nueva contraseña',
	recoveryCodeMessage: 'Ingrese el código que has recibido en tu correo electrónico',
	recoveryErrorMessage: 'Hubo un error al intentar recuperar la contraseña de esta cuenta',
	errorTryingDelete: 'Problema al eliminar',
};

export default system;
