const components = {
	component_plural: 'Components',
	add_component: 'Add component',
	edit_component: 'Edit component',
	edit_component_description: 'The component of {{quantity}} books will be updated',
	delete_component: 'Delete selected component?',
	delete_component_description: 'The component will be removed from {{quantity}} items',
	select_type: 'Associated to book type:',
	features: 'Features:',
	existent_items_on_delete_title: 'There are items assigned with this component',
	existent_items_on_delete_description: 'Modify the items with this component before deleting the component.',
	search: 'Search components',
} as const;

export default components;
