type Props = {
	keyBase: string;
	value: string;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	disabled?: boolean;
	size?: number;
	style?: React.CSSProperties;
};

function ColorPicker({ keyBase, value, onChange, disabled, size, style }: Props) {
	return (
		<div style={{ position: 'relative', display: 'inline-block', ...(style || {}) }}>
			<input
				id={`${keyBase}-color`}
				value={value}
				onChange={onChange}
				disabled={disabled}
				style={{
					opacity: 0,
					width: size || 50,
					height: size || 50,
					position: 'absolute',
					cursor: 'pointer',
				}}
				type="color"
			/>
			<div
				style={{
					width: size || 50,
					height: size || 50,
					borderRadius: '50%',
					backgroundColor: value,
					border: '2px solid black',
					boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
				}}
			></div>
		</div>
	);
}

export default ColorPicker;
