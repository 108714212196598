const genres = {
	genre_plural: 'Generos',
	add_genre: 'Agregar genero',
	edit_genre: 'Editar genero',
	edit_genre_description: 'Se actualizará el genero asignado de {{quantity}} libros.',
	delete_genre: 'Borrar el genero seleccionado?',
	delete_genre_description: 'El genero sera eliminado de {{quantity}} items',
	existent_items_on_delete_title: 'Existen elementos asignados con este genero',
	existent_items_on_delete_description: 'Modifica el genero de los items antes de eliminar el genero.',
} as const;

export default genres;
