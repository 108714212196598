import { API_DOMAIN, CURRICULUMS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function fetchCurriculums(): Promise<CurriculumType[]> {
	try {
		const response = await api.get<CurriculumType[]>(`${API_DOMAIN}${CURRICULUMS_URL}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}
export async function createCurriculums(curriculum: CurriculumType): Promise<CurriculumType | null> {
	try {
		const data = { ...curriculum, name: curriculum.name.trim() };

		const response = await api.post<CurriculumType>(`${API_DOMAIN}${CURRICULUMS_URL}`, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateCurriculums(curriculum: CurriculumType): Promise<CurriculumType | null> {
	if (!curriculum._id) {
		delete curriculum._id;
	}
	const data = { ...curriculum, name: curriculum.name.trim() };

	const url = `${API_DOMAIN}${CURRICULUMS_URL}/${curriculum._id}`;

	try {
		const response = await api.patch<CurriculumType>(url, data);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function deleteCurriculums(curriculums: CurriculumType): Promise<{ success: boolean }> {
	const url = `${API_DOMAIN}${CURRICULUMS_URL}/${curriculums._id}`;

	try {
		const response = await api.delete(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return { success: true };
	} catch (error) {
		console.error(error);
		return { success: false };
	}
}

export async function askDeleteCurriculums(curriculums: CurriculumType): Promise<{ items: number; success: boolean }> {
	const url = `${API_DOMAIN}${CURRICULUMS_URL}/ask-quantity/${curriculums._id}`;

	try {
		const response = await api.get<{ items: number; success: boolean }>(url);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		return { items: 0, success: false };
	}
}
