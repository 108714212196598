// import ComponentsTable from './ComponentTable';

import CyclesTable from './CyclesTable';

type Props = {
	cycles: CycleType[];
	edit: (component: CycleType) => void;
	onDelete: (component: CycleType) => void;
};
function CyclesList(props: Props) {
	return <CyclesTable {...props} />;
}

export default CyclesList;
