import {
	academicLevels,
	levels,
	SCHOOL_SEVEN_GRADES_5_YEARS_PROVINCES,
	SCHOOL_SIX_GRADES_SIX_YEARS_PROVINCES,
} from 'teachers-types';

export const levelsFilters: LevelFilter[] = academicLevels.reduce((all, academicLevel) => {
	const filters: LevelFilter[] =
		levels[academicLevel].length > 0
			? [
					...levels[academicLevel].map((level) => {
						const levelFilter: LevelFilter = `${academicLevel}|${level}`;
						return levelFilter;
					}),
			  ]
			: [academicLevel];
	return [...all, ...filters];
}, [] as LevelFilter[]);

export const itemSortBy = ['edition', 'price'] as const;

export const sortOrder = ['desc', 'asc'] as const;

export const provinces = [...SCHOOL_SIX_GRADES_SIX_YEARS_PROVINCES, ...SCHOOL_SEVEN_GRADES_5_YEARS_PROVINCES] as const;

export const requiredTextComponents: ComponentType[] = [
	{ name: "Student's", types: [], _id: '1' },
	{ name: 'Workbook', types: [], _id: '2' },
	{ name: "Teacher's", types: [], _id: '3' },
];

export const componentFeatures: Feature[] = [
	{ displayName: 'Subject', propName: 'subject' },
	{ displayName: 'Academic level', propName: 'academicLevel' },
	{ displayName: 'Language', propName: 'language' },
	{ displayName: 'Workload', propName: 'workload' },
	{ displayName: 'Curriculum', propName: 'curriculum' },
	{ displayName: 'Certificate', propName: 'certificate' },
	{ displayName: 'Serie', propName: 'serie' },
	{ displayName: 'Approach', propName: 'approach' },

	{ displayName: 'Genre', propName: 'genre' },
	{ displayName: 'Format', propName: 'format' },
	{ displayName: 'Cycle', propName: 'cycle' },
];
