import SubjectsTable from './SubjectsTable';

type Props = {
	subjects: SubjectType[];
	edit: (subject: SubjectType) => void;
	onDelete: (subject: SubjectType) => void;
};

function SubjectsList(props: Props) {
	return <SubjectsTable {...props} />;
}

export default SubjectsList;
