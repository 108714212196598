import { useTranslation } from 'react-i18next';
import { Select, FormControl, InputLabel, MenuItem, makeStyles, createStyles } from '@material-ui/core';
import useValues, { ParentValue } from 'hooks/useValues';

export type FilterKey = keyof Item | 'showroomType';

type Props = {
	filterKey: FilterKey;
	setFilter: (ev: React.ChangeEvent<{ value: unknown }>, key: FilterKey) => any;
	value: string;
	parentValue?: ParentValue;
};

function SelectInput({ filterKey: key, value, parentValue, setFilter }: Props) {
	const classes = useStyles();
	const { t } = useTranslation();

	const items = useValues(key, parentValue);

	return (
		<FormControl fullWidth className={classes.searchItem} variant="outlined">
			<InputLabel id={`${key}-label`}>{t(`items:${key}`)}</InputLabel>
			<Select
				fullWidth={true}
				labelId={`${key}-label`}
				id={`${key}`}
				value={value}
				label={t(`items:${key}`)}
				onChange={(ev) => setFilter(ev, key)}
			>
				{items.map((el) => {
					return (
						<MenuItem key={`${key}-${el}`} value={el}>
							{el}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}

export default SelectInput;

const useStyles = makeStyles((theme) =>
	createStyles({
		searchItem: {
			maxWidth: '400px',
		},
	})
);
