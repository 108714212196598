const certificates = {
	certificate_plural: 'Certificates',
	add_certificate: 'Add certificate',
	edit_certificate: 'Edit certificate',
	edit_certificate_description: 'The certificate of {{quantity}} books will be updated',
	delete_certificate: 'Delete selected certificate?',
	delete_certificate_description: 'The certificate will be removed from {{quantity}} items',
	existent_items_on_delete_title: 'There are items assigned with this certificate',
	existent_items_on_delete_description: 'Modify the items with this certificate before deleting the certificate.',
} as const;

export default certificates;
