const components = {
	component_plural: 'Componentes',
	add_component: 'Agregar componente',
	edit_component: 'Editar componente',
	edit_component_description: 'Se actualizará el componente asignado de {{quantity}} libros.',
	delete_component: 'Borrar el componente seleccionado?',
	delete_component_description: 'El componente sera eliminado de {{quantity}} items',
	select_type: 'Asociado a tipo de libro:',
	features: 'Caracteristicas:',
	existent_items_on_delete_title: 'Existen elementos asignados con este componente',
	existent_items_on_delete_description: 'Modifica el componente de los items antes de eliminar el componente.',
	search: 'Buscar componentes',
} as const;

export default components;
