const cycles = {
	cycle_plural: 'Ciclos',
	add_cycle: 'Agregar Ciclo',
	edit_cycle: 'Editar Ciclo',
	edit_cycle_description: 'Se actualizará el Ciclo asignado de {{quantity}} libros.',
	delete_cycle: 'Borrar el Ciclo seleccionado?',
	delete_cycle_description: 'El Ciclo sera eliminado de {{quantity}} items',
	existent_items_on_delete_title: 'Existen elementos asignados con este Ciclo',
	existent_items_on_delete_description: 'Modifica el Ciclo de los items antes de eliminar el Ciclo.',
} as const;

export default cycles;
